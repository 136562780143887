import { Injectable } from '@angular/core';
import { formatISO, parseISO } from 'date-fns';

@Injectable({
  providedIn: 'root'
})
export class LocalStorageService {
  private get warnMinOccupancyDict(): {[key: string]: boolean} {
    const s = localStorage.getItem('warnMinOccupancyDict');
    if (!s) {
      return {};
    }
    try {
      const obj = JSON.parse(s);
      return obj;
    } catch (error) {
      return {};
    }
  }

  private set warnMinOccupancyDict(value:  {[key: string]: boolean}) {
    const s = JSON.stringify(value);
    localStorage.setItem('warnMinOccupancyDict', s);
  }

  public didWarnMinOccupancy(flightId: string): boolean {
    const didWarnMinOccupancy = this.warnMinOccupancyDict[flightId];
    return didWarnMinOccupancy;
  }

  public setDidWarnMinOccupancy(flightId: string, didWarn: boolean) {
    const warnMinOccupancyDict = this.warnMinOccupancyDict;
    warnMinOccupancyDict[flightId] = didWarn;
    this.warnMinOccupancyDict = warnMinOccupancyDict;
  }

  public lastTimeWarnTurnOffAutoCreateBookingResaleQuotingFlight(): Date {
    const s = localStorage.getItem('lastWarnTurnOffAutoCreateBookingResaleQuotingFlight');
    const date = s ? parseISO(s) : undefined;
    return date;
  }

  public saveLastTimeWarnTurnOffAutoCreateBookingResaleQuotingFlight(date: Date | undefined) {
    if (!date) {
      localStorage.removeItem('lastWarnTurnOffAutoCreateBookingResaleQuotingFlight');
    }
    const s = formatISO(date);
    localStorage.setItem('lastWarnTurnOffAutoCreateBookingResaleQuotingFlight', s);
  }
}
