import moment from 'moment';
import * as luxon from 'luxon';
import { from } from 'rxjs';

import { environment } from 'src/environments/environment';
import { Aircraft } from './aircraft.class';
import { format, parseISO } from 'date-fns';

export const flightDurationDisplay = (flightDuration: number): string => moment('2015-01-01')
    .startOf('day')
    .seconds(flightDuration)
    .format('H:mm');

export const flightDateDisplay = (flightDate: string): string => {
      if (flightDate == null) {
        return '';
      }

      const d = luxon.DateTime.fromISO(flightDate);

      return d.toFormat('LLL dd, yyyy');
    };

export const flightDateDisplayFromDateObj = (flightDate: Date): string => {
  if (flightDate == null) {
    return '';
  }

  return format(flightDate, 'LLL dd, yyyy');
};

export class Flight {
  id: string;
  fromCityId: string;
  fromCity: string;
  fromCityIcaoCode: string;
  fromCityTimezone: string;
  toCityId: string;
  toCity: string;
  toCityIcaoCode: string;
  toCityTimezone: string;
  flightDateTime: string;
  arrivalDateTime: string;
  flightTimeTBD: boolean;
  aircraftId: string;
  aircraftName: string;
  totalSeats: number;
  price: number;
  wholeFlightPrice: number;
  wholeFlightPriceRetail?: number;
  dealType: string;
  currency: string;
  availableSeats: number;
  flightDuration: number;
  operatorName: string;
  aircraftImageUrl: string;
  aircraft?: Aircraft | null;
  isAvailableForSale?: boolean;
  parseObject: any;

  constructor() {
    this.aircraftImageUrl = 'assets/images/placeholder-600x240.png';
  }

  get wholeFlightPriceRetailClean(): number {
    const retailRateDefault = 0.1;
    return Math.ceil(this.wholeFlightPriceRetail ?? (this.wholeFlightPrice * (1 + retailRateDefault)));
  }

  get flightDurationDisplay(): string {
    if (this.flightDuration == null) {
      return 'N/A';
    }
    return moment('2015-01-01')
      .startOf('day')
      .minutes(this.flightDuration)
      .format('H:mm');
  }

  get fromCityTimezoneFix(): string {
    if (!this.fromCityTimezone) {
      return undefined;
    }
    return this.fromCityTimezone.replace('\n', '');
  }

  get flightDate(): string {
    if (this.flightDateTime == null) {
      return '';
    }

    const d = parseISO(this.flightDateTime);

    return format(d, 'LLL dd, yyyy');
  }

  get flightTime(): string {
    if (this.flightDateTime == null) {
      return '';
    }
    if (this.flightTimeTBD === true) {
      return 'TBD';
    }

    const local = parseISO(this.flightDateTime);

    const t = format(local, 'h:mm a');
    return t;
  }

  get arrivalTime(): string {
    if (this.arrivalDateTime == null) {
      return '';
    }

    if (this.flightTimeTBD === true) {
      return 'TBD';
    }

    const local = luxon.DateTime.fromISO(this.arrivalDateTime, {
      zone: this.fromCityTimezoneFix,
    });

    const t = local.toFormat('h:mm a');
    return t;
  }

  get minSeats(): number {
    if (!this.totalSeats) {
      return 0;
    }
    return this.totalSeats - environment.maxEmptySeats;
  }
  get bookedSeats(): number {
    if (!this.totalSeats) {
      return 0;
    }
    const availableSeats = this.availableSeats ? this.availableSeats : 0;
    return this.totalSeats - availableSeats;
  }
  get minSeatsRemains(): number {
    return Math.max(1, this.minSeats - this.bookedSeats);
  }
  get isSoldOut(): boolean {
    if (!this.availableSeats) {
      return true;
    }
    return this.availableSeats <= 0;
  }

  static createFromParseObject(x: any): Flight {
    const f = new Flight();
    f.parseObject = x;
    f.id = x.id;
    // f.fromCity = x.get('fromCity');
    // f.fromCityTimezone = x.get('fromCityTimezone');
    const fromAirport = x.get('fromAirport');
    if (fromAirport) {
      f.fromCityId = fromAirport.id;
      f.fromCity = fromAirport.get('city') ?? fromAirport.get('name');
      f.fromCityTimezone = fromAirport.get('timezone');
      f.fromCityIcaoCode = fromAirport.get('icaoCode');
    } else {
      f.fromCity = x.get('fromCity');
    }

    // f.toCity = x.get('toCity');
    // f.toCityTimezone = x.get('toCityTimezone');

    const toAirport = x.get('toAirport');

    if (toAirport) {
      f.toCityId = toAirport.id;
      f.toCity = toAirport.get('city') ?? toAirport.get('name');
      f.toCityTimezone = toAirport.get('timezone');
      f.toCityIcaoCode = toAirport.get('icaoCode');
    } else {
      f.toCity = x.get('toCity');
    }

    const isoDate = moment(x.get('flightDateTime')).toISOString();

    f.flightDateTime = isoDate;

    const arrivalDate = moment(x.get('arrivalDateTime')).toISOString();
    f.arrivalDateTime = arrivalDate;

    f.flightDuration = x.get('flightDuration');
    f.flightTimeTBD = x.get('flightTimeTBD');

    f.availableSeats = x.get('availableSeats');
    f.totalSeats = x.get('totalSeats');
    f.price = x.get('price');
    f.wholeFlightPrice = x.get('wholeFlightPrice');
    f.wholeFlightPriceRetail = x.get('wholeFlightPriceRetail');
    f.dealType = x.get('dealType');
    f.currency = x.get('currency');

    const aircraft = x.get('aircraft');
    if (aircraft) {
      f.aircraftId = aircraft.id;
      f.aircraftName = aircraft.get('name');

      const file = aircraft.get('photo');
      if (file) {
        f.aircraftImageUrl = file.url();
      }
      const operator = aircraft.get('operator');
      if (operator) {
        f.operatorName = operator.get('name');
      }
    } else {
      f.aircraftName = x.get('aircraftTitle');
      f.aircraftImageUrl = x.get('aircraftImages')
        ? x.get('aircraftImages')[0]
        : '../../assets/images/image-not-available.jpg';
      f.operatorName = x.get('operator');
    }

    if (aircraft) {
      f.aircraft = Aircraft.createFromParseObject(aircraft);
    }

    f.isAvailableForSale = x.get('isAvailableForSale');

    return f;
  }
}
