import {
  Component,
  EventEmitter,
  Input,
  OnInit,
  Output,
} from '@angular/core';
import { formatNumber } from '@angular/common';

export interface FlightCellViewModel {
  fromCity?: string;
  toCity?: string;
  flightDate?: string;
  flightTime?: string;
  aircraftName?: string;
  totalSeats?: number;
  availableSeats?: number;
  price?: number;
  currency?: string;
  isCharterPrice?: boolean;
  data?: any;
}

@Component({
  selector: 'app-flight-cell',
  templateUrl: './flight-cell.component.html',
  styleUrls: ['./flight-cell.component.scss'],
})
export class FlightCellComponent implements OnInit {
  @Input() data: FlightCellViewModel;

  @Output() click: EventEmitter<any> = new EventEmitter();

  get flightPrice(): string {
    if (!this.data?.price) {
      return 'Inquire';
    }
    return this.data.price < 1
    ? 'Inquire'
    : this.data.currency + ' ' + formatNumber(this.data.price, 'en-CA');
  }

  get isFlightTimeExist(): boolean {
    const flightTime = this.data.flightTime ?? '';
    return flightTime.length > 0;
  }

  get isSoldOut(): boolean {
    return (this.data?.availableSeats | 0) <= 0;
  }

  constructor() {}

  ngOnInit() {}

  didClickItem(event) {
    this.click.next(event);
  }
}
