import { Aircraft } from './aircraft.class';
import { FlightOperator } from './flight-operator.class';
import { FlightLeg } from './flight-leg.class';
import { AircraftOption } from './flyeasy.model';
import { Airport } from './airport.class';

export class QuotingFlight {
  id?: string;
  aircraft?: Aircraft;
  fuelStops?: number;
  fuelStopsWithPax?: number;
  airtimeEmpty?: number;
  airtimePax?: number;
  airtimePaxPercent?: number;
  operator?: FlightOperator;
  airCostEmpty?: number;
  airCostPax?: number;
  quoteAmount?: number;
  departureDate?: Date;
  returnDate?: Date;
  fromAirport?: Airport;
  toAirport?: Airport;
  flightType?: string;
  legs?: FlightLeg[];
  mainLegs?: FlightLeg[];
  trivialLegs?: FlightLeg[];
  repoToOriginDistance?: number;
  parseObject?: any;

  static createFromParseObject(x: any): QuotingFlight {
    const f = new QuotingFlight();
    f.parseObject = x;
    f.id = x.id;
    if (!x.get) {
      return f;
    }
    const aircraft = x.get('aircraft');
    if (aircraft) {
      f.aircraft = Aircraft.createFromParseObject(aircraft);
    }
    if (x.get('fuelStops')) {
      f.fuelStops = x.get('fuelStops');
    }
    if (x.get('fuelStopsWithPax')) {
      f.fuelStopsWithPax = x.get('fuelStopsWithPax');
    }
    if (x.get('airtimeEmpty')) {
      f.airtimeEmpty = x.get('airtimeEmpty');
    }
    if (x.get('airtimePax')) {
      f.airtimePax = x.get('airtimePax');
    }
    if (x.get('airtimePaxPercent')) {
      f.airtimePaxPercent = x.get('airtimePaxPercent');
    }
    const operator = x.get('operator');
    if (operator) {
      f.operator = FlightOperator.createFromParseObject(operator);
    }
    if (x.get('airCostEmpty')) {
      f.airCostEmpty = x.get('airCostEmpty');
    }
    if (x.get('airCostPax')) {
      f.airCostPax = x.get('airCostPax');
    }
    if (x.get('quoteAmount')) {
      f.quoteAmount = x.get('quoteAmount');
    }
    if (x.get('flightType')) {
      f.flightType = x.get('flightType');
    }
    const legs = x.get('legs');
    if (legs) {
      f.legs = (legs as any[]).map((leg) => FlightLeg.createFromParseObject(leg));
    }
    const mainLegs = x.get('mainLegs');
    if (mainLegs) {
      f.mainLegs = (mainLegs as any[]).map((leg) => FlightLeg.createFromParseObject(leg));
    }
    const trivialLegs = x.get('trivialLegs');
    if (trivialLegs) {
      f.trivialLegs = (trivialLegs as any[]).map((leg) => FlightLeg.createFromParseObject(leg));
    }
    if (x.get('repoToOriginDistance')) {
      f.repoToOriginDistance = x.get('repoToOriginDistance');
    }
    if (x.get('fromAirport')) {
      f.fromAirport = Airport.createFromParseObject(x.get('fromAirport'));
    }
    if (x.get('toAirport')) {
      f.toAirport = Airport.createFromParseObject(x.get('toAirport'));
    }
    if (x.get('departureDate')) {
      f.departureDate = x.get('departureDate');
    }
    if (x.get('returnDate')) {
      f.departureDate = x.get('returnDate');
    }
    return f;
  }

  static createFromFlyEasyObject(x: AircraftOption): QuotingFlight {
    const f = new QuotingFlight();
    const aircraft = x.aircraft;
    if (aircraft) {
      f.aircraft = Aircraft.createFromFlyEasyObject({aircraft, operator: x.op});
    }
    f.fuelStops = x.fuelStops;
    f.fuelStopsWithPax = x.fuelStopsWithPax;
    f.airtimeEmpty = x.airtimeEmpty;
    f.airtimePax = x.airtimePax;
    f.airtimePaxPercent = x.airtimePaxPercent;
    const operator = x.op;
    if (operator) {
      f.operator = FlightOperator.createFromFlyEasyObject(operator);
    }
    f.airCostEmpty = x.airCostEmpty;
    f.airCostPax = x.airCostPax;
    f.quoteAmount = x.quote?.amount;
    const legs = x.legs;
    if (legs) {
      f.legs = (legs as any[]).map((leg) => FlightLeg.createFromFlyEasyObject(leg));
    }
    f.repoToOriginDistance = x.repoToOriginDistance;
    return f;
  }
}

export const getBookingPriceForQuotingFlight = (quotingFlight: QuotingFlight) => {
  if (quotingFlight?.quoteAmount) {
      return Math.ceil((quotingFlight.quoteAmount ?? 0) * 1.1);
  } else {
      const airCostEmpty = quotingFlight?.airCostEmpty ?? 0;
      const airCostPax = quotingFlight?.airCostPax ?? 0;
      return Math.ceil((airCostEmpty + airCostPax) * 1.15);
  }
};
