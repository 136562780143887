import { NgModule } from '@angular/core';
import { PreloadAllModules, RouterModule, Routes } from '@angular/router';
import { AuthGuard } from './services/auth.guard';
import { AdminGuard } from './services/admin.guard';
import { SignupGuard } from './services/signup.guard';
import { MembershipGuard } from './services/membership.guard';
import { PrivacyPolicyComponent } from './components/privacy-policy/privacy-policy.component';
import { DeleteAccountGuard } from './services/delete-account.guard';
import { CancelDeleteAccountGuard } from './services/cancel-delete-account.guard';
import { OperatorGuard } from './services/operator.guard';
import { AdminOrOperatorGuard } from './services/admin-or-operator.guard';

const routes: Routes = [
  {
    path: '',
    redirectTo: 'signup',
    pathMatch: 'full',
  },
  {
    path: 'home',
    loadChildren: () =>
      import('./pages/home/home.module').then((m) => m.HomePageModule),
  },
  {
    path: 'airports',
    loadChildren: () =>
      import('./pages/airports/airports.module').then(
        (m) => m.AirportsPageModule
      ),
    canActivate: [AdminOrOperatorGuard],
  },
  {
    path: 'aircrafts',
    loadChildren: () =>
      import('./pages/aircrafts/aircrafts.module').then(
        (m) => m.AircraftsPageModule
      ),
    canActivate: [AdminOrOperatorGuard],
  },
  {
    path: 'login',
    loadChildren: () =>
      import('./pages/signin/signin.module').then((m) => m.SigninPageModule),
  },
  {
    path: 'signup',
    loadChildren: () =>
      import('./pages/signup/signup.module').then((m) => m.SignupPageModule),
    canActivate: [SignupGuard],
  },
  {
    path: 'signup-thank-you',
    loadChildren: () =>
      import('./pages/signup-thank-you/signup-thank-you.module').then(
        (m) => m.SignupThankYouPageModule
      ),
  },
  {
    path: 'operators',
    loadChildren: () =>
      import('./pages/operators/operators.module').then(
        (m) => m.OperatorsPageModule
      ),
    canActivate: [AdminGuard],
  },
  {
    path: 'aircraft-details/:id',
    loadChildren: () =>
      import('./pages/aircraft-details/aircraft-details.module').then(
        (m) => m.AircraftDetailsPageModule
      ),
    canActivate: [AdminOrOperatorGuard],
  },
  {
    path: 'flights',
    loadChildren: () =>
      import('./pages/flights/flights-by-user.module').then(
        (m) => m.FlightsByUserPageModule
      ),
    canActivate: [AuthGuard],
  },
  {
    path: 'operator/flights',
    loadChildren: () =>
      import('./pages/flights/flights-by-operator.module').then(
        (m) => m.FlightsByOperatorPageModule
      ),
    canActivate: [OperatorGuard],
  },
  {
    path: 'admin/flights',
    loadChildren: () =>
      import('./pages/flights/flights-by-admin.module').then(
        (m) => m.FlightsByAdminPageModule
      ),
    canActivate: [AdminGuard],
  },
  {
    path: 'operator/flights-with-booking-resells-sold-out',
    loadChildren: () =>
      import(
        './pages/flights/flights-with-booking-resells-sold-out-by-operator.module'
      ).then((m) => m.FlightsWithBookingResellsSoldOutByOperatorModule),
    canActivate: [OperatorGuard],
  },
  {
    path: 'admin/flights-with-booking-resells-sold-out',
    loadChildren: () =>
      import(
        './pages/flights/flights-with-booking-resells-sold-out-by-admin.module'
      ).then((m) => m.FlightsWithBookingResellsSoldOutByAdminModule),
    canActivate: [AdminGuard],
  },
  {
    path: 'flight-details/:id',
    loadChildren: () =>
      import('./pages/flight-details/flight-details.module').then(
        (m) => m.FlightDetailsPageModule
      ),
    canActivate: [AuthGuard],
  },
  {
    path: 'flight-edit/:id',
    loadChildren: () =>
      import('./pages/flight-edit/flight-edit.module').then(
        (m) => m.FlightEditPageModule
      ),
    canActivate: [AdminOrOperatorGuard],
  },
  {
    path: 'sysadmin',
    loadChildren: () =>
      import('./pages/sysadmin/sysadmin.module').then(
        (m) => m.SysadminPageModule
      ),
    canActivate: [AdminGuard],
  },
  {
    path: 'membership',
    loadChildren: () =>
      import('./pages/membership/membership.module').then(
        (m) => m.MembershipPageModule
      ),
  },
  {
    path: 'place-booking',
    loadChildren: () =>
      import('./pages/place-booking/place-booking.module').then(
        (m) => m.PlaceBookingPageModule
      ),
    canActivate: [AuthGuard],
  },
  {
    path: 'callback-requests',
    loadChildren: () =>
      import('./pages/callback-requests/callback-requests.module').then(
        (m) => m.CallbackRequestsPageModule
      ),
    canActivate: [AuthGuard, OperatorGuard],
  },
  {
    path: 'purchase-inquiry',
    loadChildren: () =>
      import('./pages/purchase-inquiry/purchase-inquiry.module').then(
        (m) => m.PurchaseInquiryPageModule
      ),
  },
  {
    path: 'airport-details/:id',
    loadChildren: () =>
      import('./pages/airport-details/airport-details.module').then(
        (m) => m.AirportDetailsPageModule
      ),
    canActivate: [AdminOrOperatorGuard],
  },
  {
    path: 'operator-details/:id',
    loadChildren: () =>
      import('./pages/operator-details/operator-details.module').then(
        (m) => m.OperatorDetailsPageModule
      ),
    canActivate: [AdminGuard],
  },
  {
    path: 'members',
    loadChildren: () =>
      import('./pages/members/members.module').then((m) => m.MembersPageModule),
    canActivate: [AdminOrOperatorGuard],
  },
  {
    path: 'member-details/:id',
    loadChildren: () =>
      import('./pages/member-details/member-details.module').then(
        (m) => m.MemberDetailsPageModule
      ),
    canActivate: [AdminGuard],
  },
  {
    path: 'guest-list/:membershipId',
    loadChildren: () =>
      import('./pages/guest-list/guest-list.module').then(
        (m) => m.GuestListPageModule
      ),
    canActivate: [AuthGuard],
  },
  {
    path: 'my-bookings',
    loadChildren: () =>
      import('./pages/my-bookings/my-bookings.module').then(
        (m) => m.MyBookingsPageModule
      ),
    canActivate: [AuthGuard],
  },
  {
    path: 'flight-bookings',
    loadChildren: () =>
      import('./pages/flight-bookings/flight-bookings.module').then(
        (m) => m.FlightBookingsPageModule
      ),
    canActivate: [AdminOrOperatorGuard],
  },
  {
    path: 'membership-info',
    loadChildren: () =>
      import('./pages/membership-info/membership-info.module').then(
        (m) => m.MembershipInfoPageModule
      ),
  },
  {
    path: 'membership-inquiry',
    loadChildren: () =>
      import('./pages/membership-inquiry/membership-inquiry.module').then(
        (m) => m.MembershipInquiryPageModule
      ),
  },
  {
    path: 'charter-quote-request',
    loadChildren: () =>
      import('./pages/charter-quote-request/charter-quote-request.module').then(
        (m) => m.CharterQuoteRequestPageModule
      ),
    // canActivate: [MembershipGuard],
  },
  {
    path: 'charter-quote-instant-request',
    loadChildren: () =>
      import('./pages/charter-quote-instant-request/charter-quote-instant-request.module').then(
        (m) => m.CharterQuoteInstantRequestPageModule
      ),
    // canActivate: [MembershipGuard],
  },
  {
    path: 'quoting-flight-options',
    loadChildren: () =>
      import('./pages/quoting-aircraft-options/quoting-aircraft-options.module').then(
        (m) => m.QuotingAircraftOptionsPageModule
      ),
    // canActivate: [MembershipGuard],
  },
  {
    path: 'quoting-flight-details',
    loadChildren: () =>
      import('./pages/flight-details/quoting-flight-details.module').then(
        (m) => m.QuotingFlightDetailsPageModule,
      ),
    // canActivate: [MembershipGuard],
  },
  {
    path: 'member-benefits',
    loadChildren: () =>
      import('./pages/member-benefits/member-benefits.module').then(
        (m) => m.MemberBenefitsPageModule
      ),
  },
  {
    path: 'buy-membership',
    loadChildren: () =>
      import('./pages/buy-membership/buy-membership.module').then(
        (m) => m.BuyMembershipPageModule
      ),
    canActivate: [AuthGuard],
  },
  {
    path: 'membership',
    loadChildren: () =>
      import('./pages/membership/membership.module').then(
        (m) => m.MembershipPageModule
      ),
  },
  // {
  //   path: 'welcome',
  //   loadChildren: () =>
  //     import('./pages/welcome/welcome.module').then((m) => m.WelcomePageModule),
  // },
  {
    path: 'privacy-policy',
    loadChildren: () =>
      import('./pages/privacy-policy/privacy-policy.module').then(
        (m) => m.PrivacyPolicyPageModule
      ),
  },
  {
    path: 'delete-account',
    canActivate: [DeleteAccountGuard, AuthGuard],
    loadChildren: () =>
      import(
        './pages/account-deletion/account-deletion/account-deletion.module'
      ).then((m) => m.AccountDeletionPageModule),
  },
  {
    path: 'delete-account-confirm',
    canActivate: [DeleteAccountGuard, AuthGuard],
    loadChildren: () =>
      import(
        './pages/account-deletion/account-deletion-confirm/account-deletion-confirm.module'
      ).then((m) => m.AccountDeletionConfirmPageModule),
  },
  {
    path: 'delete-account-finish',
    canActivate: [CancelDeleteAccountGuard, AuthGuard],
    loadChildren: () =>
      import(
        './pages/account-deletion/account-deletion-finish/account-deletion-finish.module'
      ).then((m) => m.AccountDeletionFinishPageModule),
  },
  {
    path: 'delete-account-cancel',
    canActivate: [CancelDeleteAccountGuard, AuthGuard],
    loadChildren: () =>
      import(
        './pages/account-deletion/account-deletion-cancel/account-deletion-cancel.module'
      ).then((m) => m.AccountDeletionCancelPageModule),
  },
  {
    path: 'delete-account-cancel-success',
    canActivate: [DeleteAccountGuard, AuthGuard],
    loadChildren: () =>
      import(
        './pages/account-deletion/account-deletion-cancel-success/account-deletion-cancel-success.module'
      ).then((m) => m.AccountDeletionCancelSuccessPageModule),
  },
  {
    path: 'operator-menu',
    canActivate: [OperatorGuard],
    loadChildren: () =>
      import('./pages/operator-menu/operator-menu.module').then(
        (m) => m.OperatorMenuPageModule
      ),
  },
  // {
  //   path: 'flight-edit-price/:id',
  //   loadChildren: () => import('./pages/flight-edit-price/flight-edit-price.module').then( m => m.FlightEditPricePageModule)
  // },
  {
    path: 'error/:code',
    loadChildren: () =>
      import('./pages/error/error.module').then((m) => m.ErrorPageModule),
  },
  {
    path: 'referrals',
    canActivate: [AuthGuard],
    loadChildren: () =>
      import('./pages/referral/referral.module').then(
        (m) => m.ReferralPageModule
      ),
  },
  // {
  //   path: 'notifications',
  //   loadChildren: () => import('./pages/notifications/notifications.module').then( m => m.NotificationsPageModule)
  // },
  {
    path: 'wallet',
    canActivate: [AuthGuard],
    loadChildren: () =>
      import('./pages/wallet/wallet.module').then((m) => m.WalletPageModule),
  },
  {
    path: 'booking-details/:bookingId/resales/new',
    loadChildren: () =>
      import('./pages/booking-resell-edit/booking-resell-edit-new.module').then(
        (m) => m.BookingResellEditNewPageModule
      ),
    canActivate: [AuthGuard],
  },
  {
    path: 'seat-deals',
    loadChildren: () =>
      import('./pages/flights/seat-deals.module').then(
        (m) => m.SeatDealsPageModule
      ),
    canActivate: [AuthGuard],
  },
  {
    path: 'booking-resale-details/edit/:id',
    loadChildren: () =>
      import(
        './pages/booking-resell-edit/booking-resell-edit-existing.module'
      ).then((m) => m.BookingResellEditExistingPageModule),
  },
  {
    path: 'booking-details/:bookingId/resales',
    loadChildren: () =>
      import('./pages/booking-resells/booking-resells-of-booking.module').then(
        (m) => m.BookingResellsOfBookingPageModule
      ),
  },
  {
    path: 'booking-details/:id',
    loadChildren: () =>
      import('./pages/booking-details/booking-details.module').then(
        (m) => m.BookingDetailsPageModule
      ),
  },
  {
    path: 'booking-details/:id/children',
    loadChildren: () => import('./pages/booking-children-list/booking-children-list.module').then( m => m.BookingChildrenListPageModule)
  },
  {
    path: 'booking-resale-details/:id',
    loadChildren: () =>
      import('./pages/flight-details/booking-resell-details.module').then(
        (m) => m.BookingResellDetailsPageModule
      ),
    canActivate: [AuthGuard],
  },
  {
    path: 'vip-inquiry',
    loadChildren: () => import('./pages/vip-inquiry/vip-inquiry.module').then( m => m.VipInquiryPageModule)
  },
  { path: '**', redirectTo: 'home', pathMatch: 'full' },
];

@NgModule({
  imports: [
    RouterModule.forRoot(routes, { preloadingStrategy: PreloadAllModules }),
  ],
  exports: [RouterModule],
})
export class AppRoutingModule {}
