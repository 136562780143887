/* eslint-disable max-len */
import { Flight, flightDateDisplay, flightDateDisplayFromDateObj, flightDurationDisplay } from 'src/app/models/flight.class';
import { ItineraryViewModel } from '../itinerary/itinerary.component';
import { FlightAdditionalInfo } from './flight-details.component';
import { AircraftOptionWithPrice, Leg as FlyeasyLeg } from 'src/app/models/flyeasy.model';
import { Airport } from 'src/app/models/airport-lookup-result.model';
import { QuotingFlight, getBookingPriceForQuotingFlight } from 'src/app/models/quoting-flight.class';
import { environment } from 'src/environments/environment';
import { FlightLeg } from 'src/app/models/flight-leg.class';

export interface FlightDetailsComponentViewModel {
    aircraftImageUrl?: string;
    aircraftName?: string;
    currency?: string;
    flightTime?: string;
    totalSeats?: number;
    bookedSeats?: number;
    availableSeats?: number;
    minSeats?: number;
    arrivalTime?: string;
    isBooked?: boolean;
    idCheckDidWarnMinOccupancy?: string;
    itineraries?: ItineraryViewModel[];
    additionalInfo?: FlightAdditionalInfo;
    isCharterPrice?: boolean;
    flightDisclaimers?: string[];
    showQuotingFlightDisclaimer?: boolean;
    bookingButtonTitle?: string;
    wholeAircraftPrice?: number;
    isSelectBookingAircraftTypeVisible?: boolean;
    isWholeAircraftPriceVisible?: boolean;
    isNumberOfSeatsAvailableVisible?: boolean;
    isPricePerSeatHintVisible?: boolean;
    isPurchasedSeatsVisible?: boolean;
    isCreateBookingResaleToggleVisible?: boolean;
    isBookingButtonDisabled?: boolean;
    pricePerSeatTitle?: string;
}

export const createFlightDetailsComponentViewModel = (
    { flight, isCharterPrice, isBooked,
        isSelectBookingAircraftTypeVisible,
        isWholeAircraftPriceVisible,
        isNumberOfSeatsAvailableVisible,
        isPricePerSeatHintVisible,
        isPurchasedSeatsVisible,
        isCreateBookingResaleToggleVisible
    }: {
            flight: Flight; isCharterPrice: boolean;
            isBooked: boolean; isSelectBookingAircraftTypeVisible: boolean;
            isWholeAircraftPriceVisible?: boolean;
            isNumberOfSeatsAvailableVisible: boolean;
            isPricePerSeatHintVisible: boolean;
            isPurchasedSeatsVisible: boolean;
            isCreateBookingResaleToggleVisible: boolean;
        }
): FlightDetailsComponentViewModel => {
    let flightDisclaimers = [];
    if (isCharterPrice) {
        flightDisclaimers = [`
          This is an Empty Leg flight offered by ${environment.appDisplayName} on a first-come, first-served basis
          and is subject to availability at the time of confirmation. Due to the price advantage and nature of Empty Legs,
          ${environment.appDisplayName} does not guarantee, is not obligated to, and will not provide a replacement aircraft
          in the event an Empty Leg is cancelled for any reason. Empty Legs are not scheduled flights.
          In the event of cancellation or material modification to departure times beyond what is reasonably acceptable,
          the charter price paid will be refunded to the credit card used to complete this booking request.
        `];
      } else {
        flightDisclaimers = [`
          This is an empty leg flight offered exclusively to ${environment.appDisplayName} members on a first-come, first-served basis and is
          subject to availability by the flight provider at the time of confirmation. ${environment.appDisplayName} memberships are offered by
          Attune Aviation Ltd., who does not own or operate any aircraft. All flights are performed by
          commercially-licensed flight providers who exercise full operational control of all flights. Empty legs are not
          scheduled flights. Departure and arrival locations are negotiable.
        `];
      }
      return ({
        aircraftImageUrl: flight.aircraftImageUrl,
        aircraftName: flight.aircraftName,
        currency: flight.currency,
        flightTime: flight.flightTime,
        totalSeats: flight.totalSeats,
        bookedSeats: flight.bookedSeats,
        minSeats: flight.minSeats,
        arrivalTime: flight.arrivalTime,
        isBooked,
        idCheckDidWarnMinOccupancy: flight.id,
        isCharterPrice,
        availableSeats: flight.availableSeats,
        itineraries: [{
            flightDate: flight.flightDate,
            flightTime: flight.flightTime,
            flightDurationDisplay: flight.flightDurationDisplay,
            arrivalTime: flight.arrivalTime,
            fromCityIcaoCode: flight.fromCityIcaoCode,
            toCityIcaoCode: flight.toCityIcaoCode,
            fromCity: flight.fromCity,
            toCity: flight.toCity
        }],
        additionalInfo: undefined,
        flightDisclaimers,
        bookingButtonTitle: 'Booking and Payment',
        wholeAircraftPrice: flight.wholeFlightPriceRetailClean,
        isSelectBookingAircraftTypeVisible,
        isWholeAircraftPriceVisible,
        isNumberOfSeatsAvailableVisible,
        isPricePerSeatHintVisible,
        isPurchasedSeatsVisible,
        isCreateBookingResaleToggleVisible
    });
};

const flightDisclaimersForQuotingFlight = [
    `Quotes are valid for 24 hours.`,
    `The aircraft is currently available, but not confirmed until 
    ${environment.appDisplayName} has processed your payment and emailed confirmation.`,
    `Flights are not guaranteed and may be cancelled or changed due
    to factors beyond the operator’s control.`
];

export const createFlightDetailVMFromQuotingFlightOption = ({
    aircraftOption, isCharterPrice, isBooked, airportsDict, isCreateBookingResaleToggleVisible,
    isWholeAircraftPriceVisible,
}: {
    aircraftOption: AircraftOptionWithPrice; isCharterPrice: boolean; isBooked: boolean;
    airportsDict: { [key: string]: Airport };
    isCreateBookingResaleToggleVisible: boolean;
    isWholeAircraftPriceVisible: boolean;
}): FlightDetailsComponentViewModel => {
    // Filter only related legs
    const cleanLegs: FlyeasyLeg[] = aircraftOption.mainLegs ?? [];
    const itineraries: ItineraryViewModel[] =
        cleanLegs.map((leg): ItineraryViewModel => {
            const airportFrom = leg?.origin?.icao ? airportsDict[leg?.origin?.icao] : undefined;
            const airportTo = leg?.destination?.icao ? airportsDict[leg?.destination?.icao] : undefined;
            return ({
                flightDate: flightDateDisplay(leg?.departureStr),
                // flightTime: flight.flightTime,
                flightDurationDisplay: flightDurationDisplay(Math.ceil((leg?.flightTime?.inHoursAdjusted ?? 0) * 3600)),
                // arrivalTime: flight.arrivalTime,
                fromCityIcaoCode: leg?.origin?.icao,
                toCityIcaoCode: leg?.destination?.icao,
                fromCity: airportFrom?.municipality,
                toCity: airportTo?.municipality,
            });
        });

    const aircraft = aircraftOption?.aircraft;
    return ({
        aircraftImageUrl: aircraft?.photos?.[0],
        aircraftName: aircraft?.modelDescription,
        currency: aircraft?.currency,
        // flightTime: flight.flightTime,
        totalSeats: aircraftOption.aircraft?.pax ?? 0,
        bookedSeats: 0,
        minSeats: aircraftOption.aircraft?.pax ?? 0,
        // arrivalTime: flight.arrivalTime,
        isBooked,
        // idCheckDidWarnMinOccupancy: flight.id,
        isCharterPrice,
        availableSeats: aircraftOption.aircraft?.pax ?? 0,
        itineraries,
        additionalInfo: undefined,
        flightDisclaimers: flightDisclaimersForQuotingFlight,
        showQuotingFlightDisclaimer: true,
        bookingButtonTitle: 'Reserve Now',
        wholeAircraftPrice: aircraftOption.bookingPrice,
        isCreateBookingResaleToggleVisible,
        isWholeAircraftPriceVisible
    }) as FlightDetailsComponentViewModel;
};


export const createFlightDetailVMFromQuotingFlight = ({
    quotingFlight, isCharterPrice, isBooked,
    isNumberOfSeatsAvailableVisible,
    isPurchasedSeatsVisible,
    quotingFlightLeg,
}: {
    quotingFlight: QuotingFlight; isCharterPrice: boolean; isBooked: boolean;
    isNumberOfSeatsAvailableVisible: boolean;
    isPurchasedSeatsVisible: boolean;
    // This param is used for display BookingTargetType.quotingFlightLeg
    quotingFlightLeg?: FlightLeg;
}
): FlightDetailsComponentViewModel => {
    const cleanLegs = quotingFlightLeg ? [quotingFlightLeg] : quotingFlight?.mainLegs ?? [];
    const itineraries: ItineraryViewModel[] = [];
    for (const leg of cleanLegs) {
        const airportFrom = leg?.origin;
        const airportTo = leg?.destination;
        const itinerary = ({
            flightDate: flightDateDisplayFromDateObj(leg?.departure),
            // flightTime: flight.flightTime,
            flightDurationDisplay: flightDurationDisplay(Math.ceil((leg?.flightTimeInHoursAdjusted ?? 0) * 3600)),
            // arrivalTime: flight.arrivalTime,
            fromCityIcaoCode: airportFrom?.icaoCode,
            toCityIcaoCode: airportTo?.icaoCode,
            fromCity: airportFrom?.city,
            toCity: airportTo?.city
        }) as ItineraryViewModel;
        itineraries.push(itinerary);
    }

    const aircraft = quotingFlight?.aircraft;
    return ({
        aircraftImageUrl: aircraft?.photos?.[0],
        aircraftName: aircraft?.name,
        currency: aircraft?.currency,
        // flightTime: flight.flightTime,
        totalSeats: quotingFlight?.aircraft?.seats ?? 0,
        bookedSeats: quotingFlight?.aircraft?.seats ?? 0,
        minSeats: quotingFlight?.aircraft?.seats ?? 0,
        // arrivalTime: flight.arrivalTime,
        isBooked,
        // idCheckDidWarnMinOccupancy: flight.id,
        isCharterPrice,
        availableSeats: quotingFlight?.aircraft?.seats ?? 0,
        itineraries,
        additionalInfo: undefined,
        flightDisclaimers: flightDisclaimersForQuotingFlight,
        bookingButtonTitle: 'Reserve Now',
        wholeAircraftPrice: getBookingPriceForQuotingFlight(quotingFlight),
        isSelectBookingAircraftTypeVisible: false,
        isNumberOfSeatsAvailableVisible,
        isPurchasedSeatsVisible
    });
};
