import { Booking, BookingTargetType } from './booking.class';
export class BookingResell {
    id?: string;
    booking?: Booking;
    numberOfSeats?: number;
    bookedSeats?: number;
    price?: number;
    currency?: string;
    createdAt?: Date;
    parseObject?: any;

    get isSoldOut(): boolean {
        if (!this.availableSeats) {
            return true;
        }
        return this.availableSeats <= 0;
    }

    get availableSeats(): number {
        const numberOfSeats = this.numberOfSeats ?? 0;
        const bookedSeats = this.bookedSeats ?? 0;
        return numberOfSeats - bookedSeats;
    }


    get isOfTrivialQuotingFlightLeg(): boolean {
        if (this.booking?.targetType !== BookingTargetType.quotingFlightLeg) {
          return false;
        }
        const leg = this.booking?.leg;
        const mainLegs = this.booking?.quotingFlight?.mainLegs;
        let isTrivialLegs = true;
        for (const mainLeg of mainLegs) {
          if (leg.id === mainLeg.id) {
            isTrivialLegs = false;
            break;
          }
        }
        return isTrivialLegs;
      }

    public static createFromParseObject(parseObj: any): BookingResell {
        const obj = new BookingResell();
        obj.id = parseObj.id;
        if (parseObj.get('booking')) {
            obj.booking = Booking.createFromParseObject(parseObj.get('booking'));
        }
        obj.numberOfSeats = parseObj.get('numberOfSeats');
        obj.bookedSeats = parseObj.get('bookedSeats');
        obj.price = parseObj.get('price');
        obj.currency = parseObj.get('currency');
        obj.createdAt = parseObj.get('createdAt');
        obj.parseObject = parseObj;
        return obj;
    }
}
