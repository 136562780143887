import { Component, OnInit, Input, Output, EventEmitter, ViewChild, OnChanges, SimpleChanges, OnDestroy } from '@angular/core';
import {
  ModalController,
  AlertController,
  LoadingController,
  ToastController,
  NavController,
} from '@ionic/angular';
import { ParseProvider } from 'src/app/services/parse/parse';
import { Booking, BookingStatus, BookingTargetType, bookingStatusDisplayString } from 'src/app/models/booking.class';
import { Flight } from 'src/app/models/flight.class';
import { ActivatedRoute, Router } from '@angular/router';
import { EventService } from 'src/app/services/event.service';
import { Subscription } from 'rxjs';
import { BookingService } from 'src/app/services/booking/booking.service';
import { AuthProvider } from 'src/app/services/auth/auth';
import { environment } from 'src/environments/environment';
import { FlightDetailsComponentViewModel,
  createFlightDetailVMFromQuotingFlight,
  createFlightDetailsComponentViewModel } from '../flight-details/flight-details-component-viewmodel';

@Component({
  selector: 'app-booking-details',
  templateUrl: './booking-details.component.html',
  styleUrls: ['./booking-details.component.scss'],
})
export class BookingDetailsComponent implements OnInit, OnDestroy {
  booking: Booking;
  isModal = false;
  isAdminMode = false;
  isOperatedByMe = false;
  loading: HTMLIonLoadingElement;
  private subscriptions: Subscription[] = [];

  constructor(
    public modalController: ModalController,
    private parseProvider: ParseProvider,
    public alertController: AlertController,
    private router: Router,
    private route: ActivatedRoute,
    private loadingCtrl: LoadingController,
    private eventService: EventService,
    private bookingProvider: BookingService,
    private toastCtrl: ToastController,
    private authProvider: AuthProvider,
    private navController: NavController,
  ) {}

  get statusOptions(): { title: string; value: string }[] {
    const options = Object.keys(BookingStatus).filter((option) => {
      if (this.booking.targetType === BookingTargetType.quotingFlight) {
        return true;
      } else {
        return BookingStatus[option] !== BookingStatus.PaymentPending;
      }
    }).map((option) => ({ title: bookingStatusDisplayString(BookingStatus[option]), value: BookingStatus[option] }));
    return options;
  }

  get isBookingResellEnabled(): boolean {
    return environment.isBookingResellEnabled;
  }

  get isEditable(): boolean {
    return this.isAdminMode && (this.isAdmin || this.isOperatedByMe);
  }

  get isAdmin(): boolean {
    return this.authProvider.isAdmin();
  }

  get isOperator(): boolean {
    return this.authProvider.isOperator();
  }

  get isCharterPrice(): boolean {
    return environment.isWhiteLabel;
  }

  get flightDetailViewModel(): FlightDetailsComponentViewModel {
    switch (this.booking?.targetType) {
      case BookingTargetType.flight:
      case BookingTargetType.bookingResell:
        return createFlightDetailsComponentViewModel({
          flight: this.booking?.flight, isCharterPrice: this.isCharterPrice, isBooked: true,
          isSelectBookingAircraftTypeVisible: false,
          isNumberOfSeatsAvailableVisible: false,
          isPricePerSeatHintVisible: false,
          isPurchasedSeatsVisible: false,
          isCreateBookingResaleToggleVisible: false,
        });
      case BookingTargetType.quotingFlight:
      case BookingTargetType.bookingResellQuotingFlight:
      case BookingTargetType.bookingResellQuotingFlightLeg:
        return createFlightDetailVMFromQuotingFlight({
          quotingFlight: this.booking?.quotingFlight,
          isCharterPrice: this.isCharterPrice,
          isBooked: true,
          isNumberOfSeatsAvailableVisible: false,
          isPurchasedSeatsVisible: false,
          quotingFlightLeg: this.booking?.leg
        });
      case BookingTargetType.quotingFlightLeg:
        return createFlightDetailVMFromQuotingFlight({
          quotingFlight: this.booking?.parent?.quotingFlight,
          isCharterPrice: this.isCharterPrice,
          isBooked: true,
          isNumberOfSeatsAvailableVisible: false,
          isPurchasedSeatsVisible: false,
          quotingFlightLeg: this.booking?.leg
        });
      default:
        return null;
    }
  }

  get discountAmount(): number {
    const amount = this.booking?.payment?.discount?.amount;
    if (amount) {
      return Math.abs(amount);
    } else {
      return 0;
    }
  }

  get isSelectedNumberOfPassengersVisible(): boolean {
    switch (this.booking?.targetType) {
      case BookingTargetType.flight: {
        console.log(`this.booking?.flight?.minSeats: ${this.booking?.flight?.minSeats}`);
        if (this.booking?.numberOfSeats < this.booking?.flight?.minSeats) {
          return true;
        } else {
          return false;
        }
      }
      case BookingTargetType.bookingResell:
        return true;
      default:
        return null;
    }
  }

  get availableSeatsForResell(): number {
    switch (this.booking?.targetType) {
      case BookingTargetType.flight:
      case BookingTargetType.quotingFlight:
      case BookingTargetType.quotingFlightLeg:
        return (this.booking?.numberOfSeats ?? 0) -
        (this.booking?.numberOfPassengers ?? 0);
      case BookingTargetType.bookingResell:
      case BookingTargetType.bookingResellQuotingFlight:
        return null;
      default:
        return null;
    }
  }

  get listedForResale(): number {
    switch (this.booking?.targetType) {
      case BookingTargetType.flight:
      case BookingTargetType.quotingFlight:
      case BookingTargetType.quotingFlightLeg:
        return (this.booking?.numberOfResellingSeats ?? 0);
      case BookingTargetType.bookingResell:
      case BookingTargetType.bookingResellQuotingFlight:
        return null;
      default:
        return null;
    }
  }

  get resaleSeatsSold(): string {
    switch (this.booking?.targetType) {
      case BookingTargetType.flight:
        return `${(this.booking?.numberOfBookedResellSeats ?? 0)}/${this.listedForResale}`;
      case BookingTargetType.quotingFlight:
        return null;
      case BookingTargetType.quotingFlightLeg:
        return `${(this.booking?.numberOfBookedResellSeats ?? 0)}/${this.listedForResale}`;
      case BookingTargetType.bookingResell:
      case BookingTargetType.bookingResellQuotingFlight:
        return null;
      default:
        return null;
    }
  }

  get isAvailableSeatsForResellVisible(): boolean {
    switch (this.booking?.targetType) {
      case BookingTargetType.flight:
        return true;
      case BookingTargetType.quotingFlight:
        return false;
      case BookingTargetType.quotingFlightLeg:
        return true;
      case BookingTargetType.bookingResell:
      case BookingTargetType.bookingResellQuotingFlight:
        return false;
      default:
        return false;
    }
  }

  get availableSeatsForOnboard(): number {
    switch (this.booking?.targetType) {
      case BookingTargetType.flight:
      case BookingTargetType.quotingFlight:
        return this.booking?.availableSeatsForOnboard;
      case BookingTargetType.bookingResell:
      case BookingTargetType.bookingResellQuotingFlight:
        return this.booking?.availableSeatsForOnboard;
      default:
        return null;
    }
  }

  get isAvailableSeatsForOnboardVisible(): boolean {
    switch (this.booking?.targetType) {
      case BookingTargetType.flight:
      case BookingTargetType.quotingFlight:
        return true;
      case BookingTargetType.bookingResell:
      case BookingTargetType.bookingResellQuotingFlight:
        return false;
      default:
        return false;
    }
  }

  get pricePerSeat(): number {
    switch (this.booking?.targetType) {
      case BookingTargetType.flight:
        return this.booking?.flight?.price;
      case BookingTargetType.bookingResell:
      case BookingTargetType.bookingResellQuotingFlight:
        return this.booking?.bookingResell?.price;
      case BookingTargetType.quotingFlight:
        return (this.booking?.quotingFlight?.quoteAmount ?? 0) / (this.booking?.numberOfSeats ?? 1);
      default:
        return null;
    }
  }

  get isBookingResellButtonVisible(): boolean {
    switch (this.booking?.targetType) {
      case BookingTargetType.flight:
      case BookingTargetType.quotingFlight:
      case BookingTargetType.quotingFlightLeg:
        return true;
      case BookingTargetType.bookingResell:
      case BookingTargetType.bookingResellQuotingFlight:
        return false;
      default:
        return false;
    }
  }

  get isBookingStatusDeleted(): boolean {
    return (this.booking?.status ?? BookingStatus.Deleted) === BookingStatus.Deleted;
  }

  get appName(): string {
    return environment.appDisplayName;
  }

  async ngOnInit() {
    await this.reloadData();

    this.subscriptions.push(
      this.eventService.didDeleteBookingResell.subscribe(() => {
        this.reloadData();
      })
    );

    this.subscriptions.push(
      this.eventService.didUpdateBookingResell.subscribe(() => {
        this.reloadData();
      })
    );
  }

  async reloadData() {
    console.log('reloadData');
    this.isAdminMode = this.route.snapshot.queryParams.isAdminMode;

    const extras = this.router.getCurrentNavigation()?.extras;
    let booking = extras?.state?.booking;
    if (!booking) {
      const bookingId = this.route.snapshot.params.id;
      if (bookingId) {
        try {
          await this.showLoading('Loading booking details...');
          const bookingParseObj = await this.parseProvider.findBooking(bookingId);
          if (bookingParseObj) {
            booking = Booking.createFromParseObject(bookingParseObj);
          }
          await this.hideLoading();
        } catch (error) {
          await this.hideLoading();
          await this.presentAlert(`Loading booking details failed with error: ${error.message}`);
        }
      }
    }
    if (booking) {
      if (Booking.isTrivialBookingChild(booking)) {
        await this.navController.navigateRoot('/');
      }
      this.booking = booking;
    }

    this.isOperatedByMe = await this.checkIsOperatedByMe(this.booking);
  }

  dismissModal(success) {
    this.modalController.dismiss({ success });
  }

  async showLoading(message: string) {
    const loading = await this.loadingCtrl.create({
      message
    });

    loading.present();
    this.loading = loading;
  }

  async hideLoading() {
    await this.loading?.dismiss();
    this.loading = null;
  }

  async presentAlert(message) {
    const alert = await this.alertController.create({
      header: 'Error',
      message,
      buttons: ['OK'],
    });

    await alert.present();
  }

  didClickResellButton() {
    console.log(`didClickResellButton`);
    if (this.booking.targetType === BookingTargetType.quotingFlight) {
      this.router.navigate([`booking-details/${this.booking?.id}/children`]);
    } else {
      this.router.navigate([`booking-details/${this.booking?.id}/resales`]);
    }
  }

  public async checkIsOperatedByMe(booking: Booking) {
    console.log(`checkIsOperatedByMe`);

    const user = this.authProvider.currentUser();
    if (!user.operator?.id) {
      return false;
    }

    const operator = await this.parseProvider.fetchOperator(user.operator.id);
    let isOperatedByMe = false;
    switch (booking.targetType) {
      case BookingTargetType.flight:
      case BookingTargetType.bookingResell: {
        isOperatedByMe = (operator.name === booking.flight?.operatorName || operator.id === booking.flight?.aircraft?.operator?.id);
        break;
      }
      case BookingTargetType.quotingFlight:
      case BookingTargetType.bookingResellQuotingFlight: {
        const aircraftOperator = booking.quotingFlight?.aircraft?.operator;
        isOperatedByMe = (operator.name === aircraftOperator?.name ||
          operator.id === aircraftOperator?.id);
        break;
      }
    }
    return isOperatedByMe;
  }

  doneEditing() {
    this.bookingProvider.placeBooking(this.booking).then(
      (res) => {
        this.presentToast();
        this.modalController.dismiss({ success: true });
      },
      (err) => this.presentAlert(err.message)
    );
  }

  async presentToast() {
    let msg = '';
    if (this.pricePerSeat >= 1) {
      // eslint-disable-next-line max-len
      msg = `Your booking request is confirmed! Your Trip Itinerary is being prepared and will be emailed to you shortly. 
      Due to the volatile nature of empty legs, if this flight is modified by the operator, a ${this.appName} Trip Advisor \
      will contact you immediately. 
      In the event the flight is cancelled by the operator, all authorized amounts will be immediately refunded.`;
    } else {
      msg =
        'Thank for your quote request.' +
        // eslint-disable-next-line max-len
        ' We are working on confirming availability, pricing and departure time with the flight operator and will respond to you via email very soon.';
    }
    const toast = await this.toastCtrl.create({
      message: msg,
      duration: 5000,
      position: 'bottom',
    });
    toast.present();
  }

  ngOnDestroy() {
    this.subscriptions.forEach((ele) => {
      ele.unsubscribe();
    });
    this.subscriptions = [];
  }
}
