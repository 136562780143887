import { Parse } from 'parse';

export const flattenJSON = (obj = {}, res = {}, extraKey = '') => {
    for (const key in obj) {
        if (!obj[key]) {
            continue;
        }
        if (typeof obj[key] !== 'object') {
            res[extraKey + key] = obj[key];
        } else {
            flattenJSON(obj[key], res, `${extraKey}${key}.`);
        };
    };
    return res;
};

export const andQuery = (mainQuery: Parse.Query, andQueries: Parse.Query[]) => {
    const newAddQueriesJson = andQueries.map((q) => q.toJSON().where);
    // eslint-disable-next-line no-underscore-dangle
    mainQuery._where.$and = [...(mainQuery._where?.$and ?? []), ...newAddQueriesJson];
    return mainQuery;
};

export const orQuery = (mainQuery: Parse.Query, orQueries: Parse.Query[]) => {
    const newOrQueriesJson = orQueries.map((q) => q.toJSON().where);
    // eslint-disable-next-line no-underscore-dangle
    mainQuery._where.$or = [...(mainQuery._where?.$or ?? []), ...newOrQueriesJson];
    return mainQuery;
};