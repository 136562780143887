/* eslint-disable @typescript-eslint/naming-convention */
import { Flight } from './flight.class';
import { Guest } from './guest.class';
import { User } from '../services/auth/auth';
import { Transaction } from './transaction.class';
import { BookingPayment } from './booking-payment.class';
import { BookingResell } from './booking-resell.class';
import { environment } from 'src/environments/environment';
import { QuotingFlight } from './quoting-flight.class';
import { PaymentType } from '../components/booking-form/booking-form.component';
import { FlightLeg } from './flight-leg.class';

export enum BookingStatus {
  PaymentPending = 'paymentPending',
  Pending = 'pending',
  Confirmed = 'confirmed',
  Waitlisted = 'waitlisted',
  Deleted = 'deleted',
  Cancelled = 'cancelled',
  Unbooked = 'unbooked',
}

export const bookingStatusDisplayString = (status: BookingStatus) => {
  switch (status) {
    case BookingStatus.PaymentPending:
      return 'Payment Pending';
    case BookingStatus.Pending:
      return 'Pending';
    case BookingStatus.Confirmed:
      return 'Confirmed';
    case BookingStatus.Waitlisted:
      return 'Waitlisted';
    case BookingStatus.Deleted:
      return 'Deleted';
    case BookingStatus.Cancelled:
      return 'Cancelled';
    case BookingStatus.Unbooked:
      return 'Unbooked';
  }
  return '';
};

export enum BookingTargetType {
  flight = 0,
  bookingResell,
  quotingFlight,
  bookingResellQuotingFlight,
  quotingFlightLeg,
  bookingResellQuotingFlightLeg,
}

export class Booking {
  id?: string;
  createdAt?: Date;
  targetType?: BookingTargetType;
  flight?: Flight;
  bookingResell?: BookingResell;
  quotingFlight?: QuotingFlight;
  parent?: Booking;
  leg?: FlightLeg;
  member?: User;
  status?: BookingStatus;
  numberOfSeats?: number;
  numberOfPassengers?: number;
  numberOfResellingSeats?: number;
  numberOfBookedResellSeats?: number;
  paymentReceived?: boolean;
  bookingPrice?: number;
  currency?: string;
  bookingType?: string;
  paymentType?: PaymentType;
  payment?: BookingPayment;
  payments?: BookingPayment[];
  children?: Booking[];
  fullName?: string;
  email?: string;
  phoneNumber?: string;
  address?: string;
  city?: string;
  state?: string;
  country?: string = null;
  zip?: string;
  guest?: Guest;
  discount?: Transaction;
  parseObject?: any;

  get availableSeatsForResell(): number {
    console.log(`availableSeatsForResell`);
    const numberOfPassengers = this.numberOfPassengers ?? 0;
    const numberOfSeats = this.numberOfSeats ?? 0;
    let availableSeatsForResell =
      numberOfSeats - (this.numberOfResellingSeats ?? 0);
    const minSeats = this.flight?.minSeats ?? 0;

    // if (
    //   !environment.isWhiteLabel &&
    //   numberOfPassengers < minSeats &&
    //   numberOfSeats >= minSeats
    // ) {
    //   availableSeatsForResell -= numberOfPassengers;
    // }
    availableSeatsForResell -= numberOfPassengers;

    return availableSeatsForResell;
  }

  get availableSeatsForOnboard(): number {
    return this.numberOfSeats - this.numberOfBookedResellSeats;
  }

  get targetTypeString(): string {
    switch (this.targetType) {
      case BookingTargetType.flight:
        return 'Flight';
      case BookingTargetType.bookingResell:
        return 'Booking Resale';
      case BookingTargetType.quotingFlight:
        return 'Quoting Flight';
      case BookingTargetType.bookingResellQuotingFlight:
        return 'Booking Resale Of Quoting Flight';
      default:
        return null;
    }
  }

  get depositPayment(): BookingPayment | undefined {
    for (const payment of this.payments ?? []) {
      if (payment.isDeposit) {
        return payment;
      }
    }
    return undefined;
  }

  get amountPaid(): number {
    let result = 0;
    for (const payment of this.payments ?? []) {
      result += payment.paymentAmountOrigin ?? payment.paymentAmount ?? 0;
    }
    return result;
  }

  get balanceOwing(): number {
    return this.bookingPrice - this.amountPaid;
  }

  static createFromParseObject(bookingObj: any, includeParent: boolean = true) {
    const result = new Booking();
    result.id = bookingObj.id;
    result.parseObject = bookingObj;
    if (!bookingObj.get) {
      return result;
    }
    result.targetType =
      bookingObj.get('targetType') || BookingTargetType.flight;
    result.bookingType = bookingObj.get('bookingType') || 'Initial';
    result.status = bookingObj.get('status');
    if (bookingObj.get('flight')) {
      result.flight = Flight.createFromParseObject(bookingObj.get('flight'));
    }
    if (bookingObj.get('bookingResell')) {
      result.bookingResell = BookingResell.createFromParseObject(
        bookingObj.get('bookingResell')
      );
    }
    if (bookingObj.get('quotingFlight')) {
      result.quotingFlight = QuotingFlight.createFromParseObject(
        bookingObj.get('quotingFlight')
      );
    }
    if (bookingObj.get('leg')) {
      result.leg = FlightLeg.createFromParseObject(
        bookingObj.get('leg')
      );
    }
    if (bookingObj.get('parent') && includeParent) {
      result.parent = Booking.createFromParseObject(
        bookingObj.get('parent')
      );
    }
    if (bookingObj.get('children')) {
      const children = bookingObj.get('children') ?? [];
      const childrenObj: Booking[] = [];
      for (const child of children) {
        const childObj = Booking.createFromParseObject(child, false);
        if (childObj) {
          childrenObj.push(childObj);
        }
      }
      result.children = childrenObj;
    }
    if (bookingObj.get('member')) {
      result.member = User.createFromParseObject(bookingObj.get('member'));
    }
    result.numberOfSeats = bookingObj.get('numberOfSeats') ?? 0;
    result.numberOfPassengers = bookingObj.get('numberOfPassengers') ?? 0;
    result.numberOfResellingSeats =
      bookingObj.get('numberOfResellingSeats') ?? 0;
    result.numberOfBookedResellSeats =
      bookingObj.get('numberOfBookedResellSeats') ?? 0;
    result.paymentReceived = bookingObj.get('paymentReceived') ?? false;
    result.bookingPrice = bookingObj.get('bookingPrice');
    result.currency = bookingObj.get('currency');
    result.paymentType = bookingObj.get('paymentType');
    result.email = bookingObj.get('email');
    result.phoneNumber = bookingObj.get('phoneNumber');
    result.fullName = bookingObj.get('fullName');
    result.address = bookingObj.get('address');
    result.city = bookingObj.get('city');
    result.state = bookingObj.get('state');
    result.country = bookingObj.get('country');
    result.zip = bookingObj.get('zip');
    result.createdAt = bookingObj.get('createdAt');

    if (bookingObj.get('payments')) {
      const paymentParseObjs = bookingObj.get('payments');
      const payments: BookingPayment[] = [];
      for (const paymentParseObj of paymentParseObjs) {
        const payment = BookingPayment.createFromParseObject(paymentParseObj);
        payments.push(payment);
      }
      result.payments = payments;
    }

    if (bookingObj.get('payment')) {
      const payment = BookingPayment.createFromParseObject(
        bookingObj.get('payment')
      );
      const payments: BookingPayment[] = result.payments ?? [];
      payments.push(payment);
      result.payments = payments;
    }

    return result;
  }

  static isBookingStatusCanBeReselled(booking: Booking): boolean {
    const listStatusCanBeReselled: BookingStatus[] = [
      BookingStatus.Pending,
      BookingStatus.Waitlisted,
      BookingStatus.Confirmed,
    ];
    if (
      !booking.status ||
      listStatusCanBeReselled.indexOf(booking.status) === -1
    ) {
      return false;
    }
    return true;
  }

  static isTrivialBookingChild(booking: Booking): boolean {
    if (booking.targetType !== BookingTargetType.quotingFlightLeg) {
      return false;
    }
    for (const leg of booking?.parent?.quotingFlight?.mainLegs ?? []) {
      if (leg.id === booking.leg.id) {
        return false;
      }
    }
    return true;
  }

  static separateMainAndTrivialLegsBookingChildren(booking: Booking) {
    const mainLegBookings = [];
    const trivialLegBookings = [];
    if (booking.targetType !== BookingTargetType.quotingFlight) {
      return {
        mainLegBookings,
        trivialLegBookings
      };
    }
    for (const child of booking.children) {
      let isMainLeg = false;
      for (const leg of booking?.quotingFlight?.mainLegs ?? []) {
        if (leg.id === child.leg.id) {
          isMainLeg = true;
          break;
        }
      }
      if (isMainLeg) {
        mainLegBookings.push(child);
      } else {
        trivialLegBookings.push(child);
      }
    }
    return {
      mainLegBookings,
      trivialLegBookings
    };
  }
}
