import { Injectable } from '@angular/core';
import { Subject } from 'rxjs';
import { Guest } from '../../models/guest.class';
import { ParseProvider } from '../parse/parse';
import { AuthProvider } from '../auth/auth';

@Injectable({
  providedIn: 'root'
})
export class MemberService {

  membershipId: string;
  cachedMyGuests: Guest[];
  private myGuestsSubject: Subject<Guest[]> = new Subject<Guest[]>();

  get myGuests$() {
    return this.myGuestsSubject.asObservable();
  }

  constructor(
    private parseProvider: ParseProvider,
    private authProvider: AuthProvider
  ) {
    this.authProvider.currentUser$().subscribe(u => {
      if (u == null) {
        this.cachedMyGuests = null;
        this.myGuestsSubject.next([]);
        this.membershipId = null;
      }
    });
  }

  fetchMyGuests(membershipId: string) {
    return this.parseProvider.getGuests(membershipId, 0, 1000)
      .then(res => {
        this.cachedMyGuests = res.results.map(x => Guest.createFromParseObject(x));
        this.myGuestsSubject.next(this.cachedMyGuests);
        this.membershipId = membershipId;
      });
  }

  addNewGuest(guestToSave: Guest) {
    return this.parseProvider.postGuest(guestToSave).then(
      res => {
        guestToSave.id = res.id;
        this.fetchMyGuests(this.membershipId);
        return res;
      });
  }
  makeMembershipPayment(paymentBody: any) {
    return this.parseProvider.postStripePayment('buyMembership', paymentBody).then(
      res => {
        return res;
      });
  }
}
