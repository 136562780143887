import { Injectable } from '@angular/core';
import {
  ActivatedRouteSnapshot,
  RouterStateSnapshot,
  UrlTree,
  CanLoad,
  UrlSegment,
  Router
} from '@angular/router';
import { Observable } from 'rxjs';
import { CanActivate } from '@angular/router';
import { Route } from '@angular/router';
import { AuthProvider } from './auth/auth';

@Injectable({
  providedIn: 'root'
})
export class AdminGuard implements CanActivate {
  path: ActivatedRouteSnapshot[];
  route: ActivatedRouteSnapshot;
  constructor(private authProvider: AuthProvider, private router: Router) {}

  canActivate(): boolean | Observable<boolean> | Promise<boolean> {
    if (this.authProvider.isAdmin()) {
      return true;
    }

    this.router.navigate(['/login']);
    return false;
  }
}
