import { Component, Input, OnInit } from '@angular/core';

export interface ItineraryViewModel {
  flightDate?: string;
  flightTime?: string;
  flightDurationDisplay?: string;
  arrivalTime?: string;
  fromCityIcaoCode?: string;
  toCityIcaoCode?: string;
  fromCity?: string;
  toCity?: string;
  isRightToLeft?: boolean;
}
@Component({
  selector: 'app-itinerary',
  templateUrl: './itinerary.component.html',
  styleUrls: ['./itinerary.component.scss'],
})
export class ItineraryComponent implements OnInit {
  @Input() viewModel?: ItineraryViewModel;
  @Input() showTitle = false;
  constructor() { }

  get fromCityIcaoCode(): string {
    if (this.viewModel?.isRightToLeft) {
      return this.viewModel?.toCityIcaoCode;
    } else {
      return this.viewModel?.fromCityIcaoCode;
    }
  }

  get toCityIcaoCode(): string {
    if (this.viewModel?.isRightToLeft) {
      return this.viewModel?.fromCityIcaoCode;
    } else {
      return this.viewModel?.toCityIcaoCode;
    }
  }

  get fromCity(): string {
    if (this.viewModel?.isRightToLeft) {
      return this.viewModel?.toCity;
    } else {
      return this.viewModel?.fromCity;
    }
  }

  get toCity(): string {
    if (this.viewModel?.isRightToLeft) {
      return this.viewModel?.fromCity;
    } else {
      return this.viewModel?.toCity;
    }
  }

  ngOnInit() { }

}
