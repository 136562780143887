import { Airport } from './airport.class';
import { Leg as FlyeasyLeg } from './flyeasy.model';
import { parseISO } from 'date-fns';
import { QuotingFlight } from './quoting-flight.class';

export class FlightLeg {
  id?: string;
  origin?: Airport;
  destination?: Airport;
  passengers?: number;
  isRepo?: boolean;
  departure?: Date;
  flightTimeInHours?: number;
  flightTimeInSeconds?: number;
  flightTimeDistance?: number;
  flightTimeESDA?: number;
  flightTimeInHoursAdjusted?: number;
  flightTimeGroundDistance?: number;
  fuelStops?: number;
  distanceKm?: number;
  distanceNm?: number;
  parseObject?: any;

  static createFromParseObject(x: any): FlightLeg {
    const f = new FlightLeg();
    f.parseObject = x;
    f.id = x.id;
    const originAirport = x.get('origin');
    if (originAirport) {
      f.origin = Airport.createFromParseObject(originAirport);
    }
    const destinationAirport = x.get('destination');
    if (destinationAirport) {
      f.destination = Airport.createFromParseObject(destinationAirport);
    }
    if (x.get('passengers')) {
      f.passengers = x.get('passengers');
    }
    if (x.get('isRepo')) {
      f.isRepo = x.get('isRepo');
    }
    if (x.get('departure')) {
      f.departure = x.get('departure');
    }
    if (x.get('flightTimeInHours')) {
      f.flightTimeInHours = x.get('flightTimeInHours');
    }
    if (x.get('flightTimeInSeconds')) {
      f.flightTimeInSeconds = x.get('flightTimeInSeconds');
    }
    if (x.get('flightTimeDistance')) {
      f.flightTimeDistance = x.get('flightTimeDistance');
    }
    if (x.get('flightTimeESDA')) {
      f.flightTimeESDA = x.get('flightTimeESDA');
    }
    if (x.get('flightTimeInHoursAdjusted')) {
      f.flightTimeInHoursAdjusted = x.get('flightTimeInHoursAdjusted');
    }
    if (x.get('flightTimeGroundDistance')) {
      f.flightTimeGroundDistance = x.get('flightTimeGroundDistance');
    }
    if (x.get('fuelStops')) {
      f.fuelStops = x.get('fuelStops');
    }
    if (x.get('distanceKm')) {
      f.distanceKm = x.get('distanceKm');
    }
    if (x.get('distanceNm')) {
      f.distanceNm = x.get('distanceNm');
    }
    return f;
  }

  public static createFromFlyEasyObject(leg: FlyeasyLeg): FlightLeg {
    const obj = new FlightLeg();
    if (leg.origin) {
      obj.origin = Airport.createFromFlyEasyObject(leg.origin);
    }
    if (leg.destination) {
      obj.destination = Airport.createFromFlyEasyObject(leg.destination);
    }
    obj.passengers = leg.passengers;
    obj.isRepo = leg.isRepo;
    if (leg.departureStr) {
      obj.departure = parseISO(leg.departureStr);
    }
    obj.flightTimeInHours = leg.flightTime?.inHours;
    obj.flightTimeInSeconds = leg.flightTime?.inSeconds;
    obj.flightTimeDistance = leg.flightTime?.distance;
    obj.flightTimeESDA = leg.flightTime?.ESAD;
    obj.flightTimeInHoursAdjusted = leg.flightTime?.inHoursAdjusted;
    obj.flightTimeGroundDistance = leg.flightTime?.distance;
    obj.fuelStops = leg.fuelStops;
    obj.distanceKm = leg.distance?.km;
    obj.distanceNm = leg.distance?.nm;
    return obj;
  }
}
