import { Component, OnInit } from '@angular/core';
import { NavParams, ModalController, AlertController } from '@ionic/angular';
import { User } from 'src/app/services/auth/auth';
import { ParseProvider } from 'src/app/services/parse/parse';
import moment from 'moment';
import { Membership } from 'src/app/models/membership.class';
@Component({
  selector: 'app-user-details',
  templateUrl: './user-details.component.html',
  styleUrls: ['./user-details.component.scss']
})
export class UserDetailsComponent implements OnInit {
  userToSave: User;
  passwordRepeat: string;
  password: string;
  sourceFieldTitle: string;
  membershipId: string;
  birthDate: string;
  constructor(
    public modalController: ModalController,
    navParams: NavParams,
    private parseProvider: ParseProvider,
    public alertController: AlertController
  ) {
    this.userToSave = (navParams.data.user as User) || new User();
    this.sourceFieldTitle = navParams.data.sourceFieldTitle;
    this.membershipId = navParams.data.membershipId;

    this.birthDate = moment(this.userToSave.birthDate)
      .endOf('day')
      .toString(); // give room for tz diff.

    if (this.userToSave.id) {
      // fetch email details
      this.parseProvider.findUser(this.userToSave.id).then(res => {
        this.userToSave = User.createFromParseObject(res.user);
      });
    }
  }

  ngOnInit() {}

  dismissModal(success) {
    this.modalController.dismiss({ success });
  }

  get username(): string {
    return `${this.userToSave.firstName || ''}.${this.userToSave.lastName ||
      ''}`;
  }

  doneEditing() {
    this.userToSave.name = this.username;
    const newMembership = new Membership();
    newMembership.id = this.membershipId;
    this.userToSave.membership = newMembership;
    this.userToSave.birthDate = moment(this.birthDate).format('YYYY-MM-DD');

    if (this.password) {
      this.userToSave.password = this.password;
    }

    this.parseProvider.postUser(this.userToSave).then(
      res => {
        this.userToSave.id = res.user.id;
        this.modalController.dismiss({ success: true, user: this.userToSave });
      },
      err => this.presentAlert(err.message)
    );
  }

  async presentAlert(message) {
    const alert = await this.alertController.create({
      header: 'Error',
      message,
      buttons: ['OK']
    });

    await alert.present();
  }

  get maxBirthDate(): string {
    return moment()
      .add(-18, 'years')
      .format('YYYY-MM-DD');
  }
}
