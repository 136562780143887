import { Flight } from './flight.class';
import { Guest } from './guest.class';
import { User } from '../services/auth/auth';
import { Transaction } from './transaction.class';
import { Booking } from './booking.class';
import { QuotingFlight } from './quoting-flight.class';

export class BookingPayment {
  id?: string;
  flight?: Flight;
  quotingFlight?: QuotingFlight;
  zip?: string;
  city?: string;
  isDeposit?: boolean;
  paymentAmount?: number;
  // Original payment amount when using credit card to complete wire payment
  paymentAmountOrigin?: number;
  currency?: string;
  state?: string;
  membershipPrice?: number;
  address?: string;
  country?: string = null;
  accountHolder?: User;
  fullName?: string;
  discount?: Transaction;
  booking?: Booking;
  // The payment intent id in stripe
  paymentIntentId?: string;
  parseObject?: any;

  static createFromParseObject(obj: any, {
    includeDiscount, includeBooking
  }: {
    includeDiscount?: boolean; includeBooking?: boolean;
  } = {}) {
    const result = new BookingPayment();
    result.id = obj.id;
    if(obj.get('flight')){
      result.flight = Flight.createFromParseObject(
        obj.get('flight')
      );
    }
    if(obj.get('quotingFlight')){
      result.quotingFlight = QuotingFlight.createFromParseObject(
        obj.get('quotingFlight')
      );
    }
    result.zip = obj.get('zip');
    result.city = obj.get('city');
    if (obj.get('paymentAmount')){
      result.paymentAmount = obj.get('paymentAmount');
    }
    if (obj.get('paymentAmountOrigin')){
      result.paymentAmountOrigin = obj.get('paymentAmountOrigin');
    }
    result.currency = obj.get('currency');
    result.state = obj.get('state');
    if(obj.get('membershipPrice')){
        result.membershipPrice = obj.get('membershipPrice');
    }
    if(obj.get('isDeposit') !== null && obj.get('isDeposit') !== undefined){
      result.isDeposit = obj.get('isDeposit');
  }
    result.address = obj.get('address');
    result.country = obj.get('country');
    if(obj.get('accountHolder')){
      result.accountHolder = User.createFromParseObject(
        obj.get('accountHolder')
      );
    }
    result.fullName = obj.get('fullName');
    result.paymentIntentId = obj.get('paymentIntentId');
    if(obj.get('discount') && (includeDiscount ?? true)){
      result.discount = Transaction.createFromParseObject(
        obj.get('discount')
      );
    }
    if(obj.get('booking') && (includeBooking ?? false)){
      result.booking = Booking.createFromParseObject(
        obj.get('booking')
      );
    }
    result.parseObject = obj;
    return result;
  }
}
