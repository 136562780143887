import { Component, OnInit } from '@angular/core';
import { NavParams, ModalController, AlertController } from '@ionic/angular';
import { ParseProvider } from 'src/app/services/parse/parse';
import moment from 'moment';
import { Guest } from 'src/app/models/guest.class';
import { MemberService } from '../../services/member/member.service';

@Component({
  selector: 'app-guest-details',
  templateUrl: './guest-details.component.html',
  styleUrls: ['./guest-details.component.scss']
})
export class GuestDetailsComponent implements OnInit {
  guestToSave: Guest;
  sourceFieldTitle: string;
  hostMembershipId: string;
  birthDate: string;
  constructor(
    public modalController: ModalController,
    navParams: NavParams,
    private parseProvider: ParseProvider,
    private memberService: MemberService,
    public alertController: AlertController
  ) {
    this.guestToSave = (navParams.data.guest as Guest) || new Guest();

    this.hostMembershipId = navParams.data.membershipId;
    if (this.guestToSave.birthDate) {
      this.birthDate = moment(this.guestToSave.birthDate)
        .endOf('day')
        .format();
    } else {
      this.birthDate = moment().format('YYYY-MM-DD');
    }
    // if (this.guestToSave.id) {
    //   this.parseProvider.findGuest(this.guestToSave.id).then(res => {
    //     this.guestToSave = Guest.createFromParseObject(res.user);
    //   });
    // }
  }

  get username(): string {
    return `${this.guestToSave.firstName || ''}.${this.guestToSave.lastName ||
      ''}`;
  }

  get maxBirthDate(): string {
    return moment()
      .add(0, 'years')
      .format('YYYY-MM-DD');
  }

  ngOnInit() { }

  dismissModal(success) {
    this.modalController.dismiss({ success });
  }

  doneEditing() {
    if (this.guestToSave.hostMembershipId == null) {
      this.guestToSave.hostMembershipId = this.hostMembershipId;
    }
    this.guestToSave.birthDate = moment(this.birthDate).format('YYYY-MM-DD');

    this.memberService.addNewGuest(this.guestToSave).then(
      res => {
        this.guestToSave.id = res.id;
        this.modalController.dismiss({ success: true, guest: this.guestToSave });
      },
      err => this.presentAlert(err.message)
    );
  }

  async presentAlert(message) {
    const alert = await this.alertController.create({
      header: 'Error',
      message,
      buttons: ['OK']
    });

    await alert.present();
  }
}
