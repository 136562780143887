import {
  Component,
  OnInit,
  Input,
  Output,
  EventEmitter,
  OnDestroy,
  HostListener,
} from '@angular/core';
import { AlertController } from '@ionic/angular';

import { Booking } from 'src/app/models/booking.class';
import { Flight } from 'src/app/models/flight.class';
import { AuthProvider } from 'src/app/services/auth/auth';
import { EventService } from 'src/app/services/event.service';
import { LocalStorageService } from 'src/app/services/local-storage/local-storage.service';
import { ParseProvider } from 'src/app/services/parse/parse';
import { environment } from 'src/environments/environment';
import { ItineraryViewModel } from '../itinerary/itinerary.component';
import { Subscription } from 'rxjs';
import { FlightDetailsComponentViewModel } from './flight-details-component-viewmodel';
import aOrAnPrefix from 'indefinite';

export interface SelectedPassengersValue {
  numberOfPassengers?: number;
  numberOfSeats?: number;
}

export interface FlightAdditionalInfo {
  title?: string;
  detail?: string;
}

export enum BookingAircraftType {
  wholeAircraft = 'wholeAircraft',
  sharedFlight = 'sharedFlight'
}

@Component({
  selector: 'app-flight-details',
  templateUrl: './flight-details.component.html',
  styleUrls: ['./flight-details.component.scss'],
})
export class FlightDetailsComponent implements OnInit, OnDestroy {
  @Output() changeSelectedNumberOfPassengers =
    new EventEmitter<SelectedPassengersValue>();
  @Output() changeDiscountAmount = new EventEmitter<number>();
  @Output() changeIsDiscountAmountValid = new EventEmitter<boolean>();
  @Output() changeSelectedBookingAircraftType = new EventEmitter<BookingAircraftType>();
  @Output() changeAutoCreatingBookingResell = new EventEmitter<boolean>();

  // @Input() flight: Flight;
  // @Input() booking: Booking = null;
  @Input() viewModel: FlightDetailsComponentViewModel;
  @Input() pricePerSeat = 0;
  @Input() isSoldOutVisible?: boolean;
  @Input() isSelectedNumberOfPassengersVisible: boolean;
  @Input() isSelectedNumberOfPassengersEditable: boolean;
  @Input() discountAmount: number = null;
  @Input() selectedNumberOfSeats = 0;
  @Input() selectedNumberOfPassengers = 0;
  @Input() isShowingSeatInfo = false;
  @Input() selectedBookingAircraftType: BookingAircraftType = BookingAircraftType.wholeAircraft;
  @Input() isAutoCreatingBookingResell = false;
  @Input() isAutoCreatingBookingResellToggleDisabled = false;

  discountAmountErrorMessage: string = null;
  updateFlightSubscription: Subscription | null;
  choiceSize = 'default';

  constructor(
    private authProvider: AuthProvider,
    private alertController: AlertController,
    private localStorageService: LocalStorageService,
    private parseProvider: ParseProvider,
    private eventService: EventService
  ) {}

  get itineraryViewModels(): ItineraryViewModel[] {
    return this.viewModel?.itineraries ?? [];
  }

  get walletBalance(): number {
    let balance = this.authProvider.currentUser()?.walletBalance || 0;
    if (this.viewModel.currency === 'CAD') {
      balance = balance * 1.3;
    }
    return balance;
  }
  get minSeatsRemains(): number {
    return (this.viewModel?.minSeats ?? 0) - (this.viewModel?.bookedSeats ?? 0);
  }

  get availableSeats(): number {
    return (this.viewModel?.availableSeats ?? 0);
  }

  get appName(): string {
    return environment.appDisplayName;
  }

  get aPrefixAppName(): string {
    return aOrAnPrefix(this.appName);
  }

  // get pricePerPassenger(): number | null {
  //   if (this.booking) {
  //     return this.booking.bookingPrice / this.booking.numberOfSeats;
  //   } else if (this.flight) {
  //     return this.flight.price;
  //   } else {
  //     return null;
  //   }
  // }

  get isAdmin(): boolean {
    return (
      this.authProvider.currentUser() && this.authProvider.currentUser().isAdmin
    );
  }

  @HostListener('window:resize', ['$event'])
  onResize() {
    this.detectWindowSizeChange();
  }

  detectWindowSizeChange() {
    if (window.innerWidth <= 375) {
      this.choiceSize = 'small';
    } else {
      this.choiceSize = 'default';
    }
  }

  ngOnInit() {
    this.detectWindowSizeChange();
  }

  ngOnDestroy() {
    // this.updateFlightSubscription.unsubscribe();
    // this.updateFlightSubscription = null;
  }

  async discountAmountChanged(event) {
    let errorMessage: string = null;
    if (
      (event.detail.value >= 0 && event.detail.value <= this.walletBalance)
    ) {
      errorMessage = null;
    } else {
      errorMessage = `Amount must be positive and not exceed your balance which is ${this.walletBalance} ${this.viewModel?.currency}`;
    }

    this.discountAmountErrorMessage = errorMessage;
    this.changeIsDiscountAmountValid.next(errorMessage === null);
    this.changeDiscountAmount.next(event.detail.value);
  }

  async updateSelectedSeats(seats: number) {
    const minOccupancy = this.minSeatsRemains ?? 0;
    const selectedNumberOfPassengers = this.selectedNumberOfPassengers + seats;
    const idCheckDidWarnMinOccupancy = this.viewModel?.idCheckDidWarnMinOccupancy;
    const didWarn = idCheckDidWarnMinOccupancy ? this.localStorageService.didWarnMinOccupancy(
      idCheckDidWarnMinOccupancy
    ) : true;
    if (selectedNumberOfPassengers <= minOccupancy) {
      // if (!didWarn && !this.viewModel?.isCharterPrice && this.selectedBookingAircraftType === BookingAircraftType.sharedFlight) {
      //   const message = `The aircraft has ${
      //     this.viewModel?.totalSeats ?? 0
      //   } seats, requiring a minimum purchase of ${
      //     minOccupancy
      //   } seats to confirm the trip. Even if fewer seats are booked, \
      //   the cost equivalent to ${
      //     minOccupancy
      //   } seats will apply.
      //   Shared Flights allow the opportunity to offset your cost by reselling unoccupied \
      //   seats through 'My Bookings' after you book the aircraft. You keep 90% of the \
      //   revenue, and decide how many seats to sell, or leave empty.
      //   `;

      //   const alert = await this.alertController.create({
      //     header: '',
      //     message,
      //     buttons: [
      //       {
      //         text: 'Acknowledge',
      //         handler: () => {
      //           this.localStorageService.setDidWarnMinOccupancy(
      //             idCheckDidWarnMinOccupancy,
      //             true
      //           );
      //         },
      //       },
      //     ],
      //   });

      //   await alert.present();
      // }

      this.selectedNumberOfSeats = minOccupancy;
    } else {
      this.selectedNumberOfSeats += seats;
    }
    this.selectedNumberOfPassengers = selectedNumberOfPassengers;
    this.changeSelectedNumberOfPassengers.emit({
      numberOfSeats: this.selectedNumberOfSeats,
      numberOfPassengers: this.selectedNumberOfPassengers,
    });
    console.log(
      `FlightDetailsComponent numberOfSeats: ${this.selectedNumberOfSeats} selectedNumberOfPassengers: ${this.selectedNumberOfPassengers}`
    );
  }

  colorOfBookingAircraftTypeChoice(choice: string) {
    if (choice === this.selectedBookingAircraftType) {
      return 'booking-flight-type-selected';
    } else {
      return 'booking-flight-type-normal';
    }
  }

  selectBookingAircraftTypeChoice(choice: string) {
    this.selectedBookingAircraftType = choice as BookingAircraftType;
    this.changeSelectedBookingAircraftType.next(choice as BookingAircraftType);
  }

  questionMarkClicked() {
    console.log(`questionMarkClicked`);
  }

  async didChangeAutoCreatingBookingResell(event) {
    const value = event.detail.checked;
    this.changeAutoCreatingBookingResell.next(value);
  }
}
