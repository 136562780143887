import { NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { RouteReuseStrategy } from '@angular/router';

import { IonicModule, IonicRouteStrategy } from '@ionic/angular';
import { SplashScreen } from '@awesome-cordova-plugins/splash-screen/ngx';
// import { StatusBar, Style } from '@capacitor/status-bar';

import { AppComponent } from './app.component';
import { AppRoutingModule } from './app-routing.module';
import { ServiceWorkerModule } from '@angular/service-worker';
import { environment } from '../environments/environment';
import { ParseProvider } from './services/parse/parse';
import { AuthProvider } from './services/auth/auth';
import { AppVersion } from '@awesome-cordova-plugins/app-version/ngx';
import { FormsModule } from '@angular/forms';
import { BookingDetailsComponent } from './components/booking-details/booking-details.component';
import { GuestDetailsComponent } from './components/guest-details/guest-details.component';
import { UserDetailsComponent } from './components/user-details/user-details.component';
import { CallNumber } from '@awesome-cordova-plugins/call-number/ngx';
import { EmailComposer } from '@awesome-cordova-plugins/email-composer/ngx';
import { HttpClientModule, HTTP_INTERCEPTORS } from '@angular/common/http';
// import { Stripe } from '@awesome-cordova-plugins/stripe/ngx';
import { SharedModule } from './components/shared.module';
import { SharedPipesModule } from './services/pipes/shared-pipes.module';
import { FlightFilters } from './components/flight-filters/flight-filters.component';
import { ClipboardModule } from 'ngx-clipboard';

@NgModule({
  declarations: [AppComponent],
  entryComponents: [BookingDetailsComponent, GuestDetailsComponent, UserDetailsComponent, FlightFilters],
  imports: [
    BrowserModule.withServerTransition({ appId: 'tappjet'}),
    HttpClientModule,
    IonicModule.forRoot(),
    AppRoutingModule,
    FormsModule,
    SharedModule, SharedPipesModule,
    ServiceWorkerModule.register('ngsw-worker.js', {
      enabled: environment.production
    }),
    ClipboardModule
  ],
  providers: [
    // StatusBar,
    SplashScreen,
    // Stripe,
    { provide: RouteReuseStrategy, useClass: IonicRouteStrategy },
    ParseProvider,
    AuthProvider,
    CallNumber,
    EmailComposer,
    AppVersion,
  ],
  bootstrap: [AppComponent]
})
export class AppModule { }
