import { CUSTOM_ELEMENTS_SCHEMA, NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule } from '@angular/forms';
import { Routes, RouterModule } from '@angular/router';

import { IonicModule } from '@ionic/angular';

import { FlightDetailsComponent } from 'src/app/components/flight-details/flight-details.component';
import { BookingDetailsComponent } from './booking-details/booking-details.component';
import { UserDetailsComponent } from './user-details/user-details.component';
import { GuestDetailsComponent } from './guest-details/guest-details.component';
import { MembershipTermsPage } from './membership-terms/membership-terms.page';
import { PrivacyPolicyComponent } from './privacy-policy/privacy-policy.component';
import { FlightFilters } from './flight-filters/flight-filters.component';
import { ItineraryComponent } from './itinerary/itinerary.component';
import { AccordionComponent } from './accordion/accordion.component';
import { FlightCellComponent } from './flight-cell/flight-cell.component';
import { BookingCellComponent } from './booking-cell/booking-cell.component';

@NgModule({
  imports: [
    CommonModule,
    IonicModule,
    FormsModule
  ],
  declarations: [
    BookingDetailsComponent,
    FlightDetailsComponent,
    UserDetailsComponent,
    GuestDetailsComponent,
    MembershipTermsPage,
    PrivacyPolicyComponent,
    FlightFilters,
    ItineraryComponent,
    AccordionComponent,
    FlightCellComponent,
    BookingCellComponent
  ],
  entryComponents: [ MembershipTermsPage, PrivacyPolicyComponent, AccordionComponent ],
  exports: [
    BookingDetailsComponent,
    FlightDetailsComponent,
    FlightFilters,
    ItineraryComponent,
    AccordionComponent,
    PrivacyPolicyComponent,
    FlightCellComponent,
    BookingCellComponent
  ],
  schemas: [CUSTOM_ELEMENTS_SCHEMA]
})
export class SharedModule { }
