import { EventEmitter, Injectable } from '@angular/core';
import { BookingResell } from '../models/booking-resell.class';
import { Booking } from '../models/booking.class';
import { Flight } from '../models/flight.class';
import { BookingSubmitResultData } from '../components/booking-submit-result/booking-submit-result.component';

@Injectable({ providedIn: 'root' })
export class EventService {
    public didUpdateFlight: EventEmitter<Flight> = new EventEmitter();
    public didDeleteFlight: EventEmitter<Flight> = new EventEmitter();
    public didBookFlightSuccess: EventEmitter<{flight: Flight; booking: Booking}> = new EventEmitter();
    public didBookBookingResellSuccess: EventEmitter<Booking> = new EventEmitter();
    public didUpdateBookingResell: EventEmitter<BookingResell> = new EventEmitter();
    public didAddBookingResells: EventEmitter<BookingResell[]> = new EventEmitter();
    public didDeleteBookingResell: EventEmitter<BookingResell> = new EventEmitter();
    public didCompletePaymentForBooking: EventEmitter<BookingSubmitResultData> = new EventEmitter();
}
