import { Component, NgZone, OnDestroy } from '@angular/core';
import { Platform, LoadingController, AlertController } from '@ionic/angular';
import { Capacitor } from '@capacitor/core';
import { Router } from '@angular/router';
import { AppVersion } from '@awesome-cordova-plugins/app-version/ngx';
import { CallNumber } from '@awesome-cordova-plugins/call-number/ngx';
import { StatusBar, Style } from '@capacitor/status-bar';
import { App, URLOpenListenerEvent } from '@capacitor/app';
import { FirebaseDynamicLinks } from '@pantrist/capacitor-firebase-dynamic-links';
import { Stripe } from '@capacitor-community/stripe';
import { compareVersions } from 'compare-versions';
import { Observable, Subscription } from 'rxjs';
import { delay, map } from 'rxjs/operators';
import { BranchInitEvent, BranchDeepLinks } from 'capacitor-branch-deep-links';
import { FCM } from '@capacitor-community/fcm';
import { PushNotifications } from '@capacitor/push-notifications';

import { environment } from 'src/environments/environment';
import { AuthProvider } from './services/auth/auth';
import { PaymentSheetService } from './services/payment/payment-sheet.service';
import { ParseProvider } from './services/parse/parse';

// const { Browser } = Plugins;
@Component({
  selector: 'app-root',
  templateUrl: 'app.component.html',
})
export class AppComponent implements OnDestroy {
  appVersionNumber: string;
  appVersionCode: string | number;
  public appPages = [];

  homePage = {
    title: 'Home',
    url: '/home',
    icon: 'home',
  };
  availableFlightsPage = {
    title: 'View Empty Legs',
    url: '/flights',
    icon: 'airplane',
  };
  seatsDealsPage = {
    title: 'View Seats Deals',
    url: '/seat-deals',
    icon: 'fa-solid fa-handshake',
    type: 'font-awesome',
  };
  charterQuotePage = {
    title: 'Get Instant Quote',
    url: '/charter-quote-instant-request',
    icon: 'create',
  };
  commonPages = this.isCharterPrice
    ? [this.homePage, this.availableFlightsPage, this.charterQuotePage]
    : [
        this.homePage,
        this.availableFlightsPage,
        this.seatsDealsPage,
        this.charterQuotePage,
      ];

  myBookingPage = {
    title: 'My Bookings',
    url: '/my-bookings',
    icon: 'briefcase',
  };

  sysAdminPage = {
    title: 'System Admin',
    url: '/sysadmin',
    icon: 'settings',
  };

  operatorPage = {
    title: 'Operator',
    url: '/operator-menu',
    icon: 'settings',
  };

  premiumMembership = {
    title: 'Buy Membership',
    url: '/membership',
    icon: 'people-circle-outline',
  };

  aboutMembership = {
    title: 'About Membership',
    url: '/membership',
    icon: 'people-circle-outline',
  };

  memberBenefit = {
    title: 'Member Benefits',
    url: '/member-benefits',
    icon: 'ribbon-outline',
  };

  charterTermsAndConditions = {
    title: 'Terms and Conditions',
    url: '',
    icon: 'people-circle-outline',
  };

  private subcriptions: Subscription[] = [];

  constructor(
    private platform: Platform,
    private callNumber: CallNumber,
    private parse: ParseProvider,
    public authProvider: AuthProvider,
    private router: Router,
    private loadCtrl: LoadingController,
    private alertController: AlertController,
    private appVersion: AppVersion,
    private paymentSheetService: PaymentSheetService,
    private zone: NgZone
  ) {
    this.initializeApp();
    this.platform.ready().then(() => {
      if (Capacitor.isPluginAvailable('StatusBar')) {
        StatusBar.setOverlaysWebView({ overlay: this.platform.is('ios') });
        if (this.platform.is('ios')) {
          StatusBar.setStyle({ style: Style.Light });
        }
        // if (this.platform.is('android')) {
        //   StatusBar.setBackgroundColor({ color: '#ffffff' });
        // }
      }
    });
  }

  get visitWebsite(): string {
    return environment.visitWebsite;
  }

  get contactLink(): string {
    return `tel:+${environment.contactPhone}`;
  }

  get privacyLink(): string {
    return environment.privacyLink;
  }

  get isCharterPrice(): boolean {
    return environment.isWhiteLabel;
  }

  get showRequestDeleteAccount(): Observable<boolean> {
    return this.authProvider.currentUser$().pipe(
      map((user) => {
        if (user && !user?.requestedDeleteAt) {
          return true;
        } else {
          return false;
        }
      })
    );
  }

  get showCancelDeleteAccount(): Observable<boolean> {
    return this.authProvider.currentUser$().pipe(
      map((user) => {
        if (user && user?.requestedDeleteAt) {
          return true;
        } else {
          return false;
        }
      })
    );
  }

  get appName(): string {
    return environment.appDisplayName;
  }

  get currentUser(): string {
    return this.authProvider.currentUser()
      ? this.authProvider.currentUser().name
      : null;
  }

  async initializeApp() {
    // this.platform.ready().then(() => {
    //   if (Capacitor.isPluginAvailable("SpalshScreen")) {
    //     Plugins.SplashScreen.hide();
    //   }
    // });

    await Stripe.initialize({
      publishableKey: environment.stripeKey,
    });
    App.addListener('appUrlOpen', (event: URLOpenListenerEvent) => {
      this.zone.run(() => {
        // Example url: https://beerswift.app/tabs/tab2
        // slug = /tabs/tab2
        if (
          event.url.startsWith('https://') ||
          event.url.startsWith('http://')
        ) {
          const url = new URL(event.url);
          const path = url.href.replace(url.origin, '');
          this.router.navigateByUrl(path);
        } else if (event.url.startsWith('tappjet://app')) {
          const slug = event.url.replace('tappjet://app', '');
          console.log('slug: ' + slug);
          if (slug) {
            this.router.navigateByUrl(slug);
          }
        }
        // If no match, do nothing - let regular routing
        // logic take over
      });
    });

    FirebaseDynamicLinks.addListener('deepLinkOpen', (data) => {
      console.log('deepLinkOpen' + JSON.stringify(data));
      const url = new URL(data.url);
      const slug = url.pathname;
      if (slug) {
        this.router.navigateByUrl(`${slug}${url.search}`);
      }
    });

    if (this.platform.is('desktop')) {
      // hide member's common pages until phase 2 or 3. desktop is for admin only by then.
    } else {
      // this.appPages = [...this.commonPages];
      // if (this.currentUser) {
      //   this.appPages.push(this.myBookingPage);
      // }
    }

    const checkAppVersionAndMaintenance = async () => {
      if (this.platform.is('mobile')) {
        try {
          await this.checkAppVersion();
        } catch {}
      }
      try {
        await this.checkIsInMaintenance();
      } catch {}
    };
    checkAppVersionAndMaintenance();
    App.addListener('resume', () => {
      checkAppVersionAndMaintenance();
    });

    this.authProvider.currentUser$().subscribe((user) => {
      if (user) {
        this.appPages = [...this.commonPages, this.myBookingPage];
        if (user.isAdmin) {
          this.appPages = [
            ...this.commonPages,
            this.myBookingPage,
            this.sysAdminPage,
          ];
        } else if (user.operator) {
          this.appPages = [
            ...this.commonPages,
            this.myBookingPage,
            this.operatorPage,
          ];
        }
        if (!this.authProvider.isVIPMember()) {
          this.premiumMembership.title = this.authProvider.isMember() ? 'VIP Membership' : 'Buy Membership';
          this.appPages = [...this.appPages, this.premiumMembership];
        }
        this.parse.fillBasicAirports();
      } else {
        if (environment.showMembershipSideMenuItem) {
          this.appPages = [...this.commonPages, this.aboutMembership];
        } else {
          this.appPages = [...this.commonPages, this.charterTermsAndConditions];
        }
        // if (!localStorage.getItem('installed')) {
        //   this.router.navigate(["/welcome"]);
        // }
      }

      if (!this.authProvider.isMember()) {
        this.appPages = [...this.appPages, this.memberBenefit];
      }
    });

    this.platform.ready().then(() => {
      console.log(`setup BranchDeepLinks`);
      BranchDeepLinks.addListener('init', (event: BranchInitEvent) => {
        // Retrieve deeplink keys from 'referringParams' and evaluate the values to determine where to route the user
        // Check '+clicked_branch_link' before deciding whether to use your Branch routing logic
        console.log(
          'BranchDeepLinks: ' + JSON.stringify(event.referringParams)
        );
        const urlString = event.referringParams.$canonical_url;
        console.log('Canonical url: ' + urlString);
        if (urlString) {
          const url = new URL(urlString);
          const path = url.href.replace(url.origin, '');
          console.log('path: ' + path);
          this.router.navigateByUrl(path);
        } else {
          const deepLinkPath = event.referringParams.$deeplink_path;
          if (deepLinkPath) {
            this.router.navigateByUrl(deepLinkPath);
          }
        }
      });

      BranchDeepLinks.addListener('initError', (error: any) => {
        console.error('BranchDeepLinks error: ' + JSON.stringify(error));
      });
    });

    this.loadBranchIOScript();

    if (this.platform.is('mobile')) {
      try {
        await PushNotifications.requestPermissions();
        await PushNotifications.register();
        // Enable the auto initialization of the library
        await FCM.setAutoInit({ enabled: true });
      } catch (error) {
        console.log(
          `Config push notification and firebase cloud messaging encounter error: ${error}`
        );
      }

      this.subcriptions.push(
        this.authProvider.currentUser$().subscribe((currentUser) => {
          const body = async () => {
            try {
              const result = await FCM.getToken();
              const firebaseToken = result.token;
              if (currentUser) {
                await this.authProvider.setFirebaseToken(firebaseToken);
              }
            } catch (error) {
              // Do nothing
            }
          };
          body();
        })
      );
    }
  }

  loadBranchIOScript() {
    if (this.platform.is('mobileweb')) {
      const node = document.createElement('script'); // creates the script tag
      node.text = `
        // load Branch
        (function(b,r,a,n,c,h,_,s,d,k){if(!b[n]||!b[n]._q){for(;s<_.length;)c(h,_[s++]);d=r.createElement(a);d.async=1;d.src="https://cdn.branch.io/branch-latest.min.js";k=r.getElementsByTagName(a)[0];k.parentNode.insertBefore(d,k);b[n]=h}})(window,document,"script","branch",function(b,r){b[r]=function(){b._q.push([r,arguments])}},{_q:[],_v:1},"addListener applyCode autoAppIndex banner closeBanner closeJourney creditHistory credits data deepview deepviewCta first getCode init link logout redeem referrals removeListener sendSMS setBranchViewData setIdentity track validateCode trackCommerceEvent logEvent disableTracking qrCode".split(" "), 0);
        // init Branch
        branch.init("${environment.branchIOKey}");
      `; // sets the source (insert url in between quotes)
      node.type = 'text/javascript'; // set the script type
      node.async = true; // makes script run asynchronously
      // append to head of document
      document.getElementsByTagName('head')[0].appendChild(node);
    }
  }

  async checkAppVersion() {
    const readySource = await this.platform.ready();
    console.log('Platform ready from', readySource);
    const appName = await this.appVersion.getAppName();
    console.log('getAppName:', appName);
    const packageName = await this.appVersion.getPackageName();
    console.log('getPackageName:', packageName);
    this.appVersionNumber = await this.appVersion.getVersionNumber();
    console.log('getVersionNumber:', this.appVersionNumber);
    this.appVersionCode = await this.appVersion.getVersionCode();
    console.log('verCode:', this.appVersionCode);
    let latestVersionNumber = '';
    if (this.platform.is('ios')) {
      latestVersionNumber = await this.parse.getConfig(
        'latestVersionNumberIOS'
      );
    } else if (this.platform.is('android')) {
      latestVersionNumber = await this.parse.getConfig(
        'latestVersionNumberAndroid'
      );
    }
    console.log('latestVersionNumber:', latestVersionNumber);
    let latestVersionCode = '';
    if (this.platform.is('ios')) {
      latestVersionCode = await this.parse.getConfig('latestVersionCodeIOS');
    } else if (this.platform.is('android')) {
      latestVersionCode = await this.parse.getConfig(
        'latestVersionCodeAndroid'
      );
    }
    console.log('latestVersionCode:', latestVersionCode);
    const compareVersionNameResult = compareVersions(
      latestVersionNumber,
      this.appVersionNumber
    );
    const compareVersionCodeResult = compareVersions(
      latestVersionCode,
      `${this.appVersionCode}`
    );
    let needUpdate = false;
    if (compareVersionNameResult > 0) {
      needUpdate = true;
    } else if (
      compareVersionNameResult === 0 &&
      compareVersionCodeResult > 0
    ) {
      needUpdate = true;
    }
    if (needUpdate) {
      const forceUpdate = await this.parse.getConfig('forceUpdate');
      if (forceUpdate) {
        console.log('must update!');
        let url = '';
        if (this.platform.is('ios')) {
          url = await this.parse.getConfig('appUrl_iOS');
        } else if (this.platform.is('android')) {
          url = await this.parse.getConfig('appUrl_android');
        }
        console.log('app url:', url);
        if (url) {
          this.presentUpdateAppAlert(url);
        }
      }
    }
  }

  async presentUpdateAppAlert(url) {
    try {
      await this.alertController.dismiss();
    } catch {}
    const isNewVersionPublished = await this.parse.getConfig('isNewVersionPublished');
    const message = isNewVersionPublished ?
    `A newer version of the app is available for download. Tap 'OK' to update now.` :
    'A new version is being deployed. Please try again later.';
    const alert = await this.alertController.create({
      header: 'Please Update',
      subHeader: '',
      message,
      backdropDismiss: false,
      buttons: [
        {
          text: 'Okay',
          handler: () => {
            if (isNewVersionPublished) {
              window.open(url);
            }
            this.presentUpdateAppAlert(url);
          },
        },
      ],
    });

    await alert.present();
  }

  async checkIsInMaintenance() {
    const isInMaintenance = await this.parse.getConfig('isInMaintenance');
    console.log(`checkIsInMaintenance: ${isInMaintenance}`);
    if (isInMaintenance) {
      this.presentMaintenanceAlert();
    }
  }

  async presentMaintenanceAlert() {
    try {
      await this.alertController.dismiss();
    } catch {}

    let message = '';
    if (this.platform.is('mobile')) {
      message = `${this.appName} is under maintenance. Please try again later by force close and reopen app to continue using.`;
    } else {
      message = `${this.appName} is under maintenance. Please try again later`;
    }

    const alert = await this.alertController.create({
      header: 'Maintenance in progress',
      subHeader: '',
      message,
      backdropDismiss: false,
      buttons: [
        {
          text: 'Okay',
          handler: () => {
            this.presentMaintenanceAlert();
          },
        },
      ],
    });

    await alert.present();
  }

  async onLogout() {
    const loader = await this.loadCtrl.create({
      message: 'Logging out...',
    });

    await loader.present();
    try {
      await this.authProvider.setFirebaseToken(null);
      const succeed = await this.authProvider.signout().toPromise();
      await loader.dismiss();
      if (succeed) {
        this.router.navigate(['/home'], {
          queryParams: { refresh: new Date().getTime() },
        });
      }
    } catch (error) {
      console.log(`Logout error: ${error}`);
      await loader.dismiss();
    }
  }

  async onContactSales($event) {
    if (this.platform.is('cordova')) {
      this.callNumber
        .callNumber('+18772125720', true)
        .then((res) => console.log('Launched dialer!', res))
        .catch((err) => console.log('Error launching dialer', err));
    } else {
      // await Browser.open({ url: 'tel:+18772125720' });
    }
    $event.preventDefault();
  }

  ngOnDestroy() {
    for (const subscription of this.subcriptions) {
      subscription.unsubscribe();
    }
    this.subcriptions = [];
  }
}
