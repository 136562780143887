import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Stripe as StripeCap, PaymentSheetEventsEnum, ApplePayEventsEnum, GooglePayEventsEnum, Stripe, PaymentSheetResultInterface } from '@capacitor-community/stripe';
import { loadStripe } from '@stripe/stripe-js';
import { first } from 'rxjs/operators';
import { environment } from 'src/environments/environment';
import { AuthProvider } from 'src/app/services/auth/auth';
import { Parse } from 'parse';
import { ParseProvider } from '../parse/parse';

interface PresentPaymentSheetOptions {
  amount: number;
  currency: string;
  description?: string | null;
  metadata?: Object | null;
}

@Injectable({
  providedIn: 'root'
})

export class PaymentSheetService {

  constructor(private http: HttpClient,
    private authProvider: AuthProvider) { }

  async presentPaymentSheet(options: PresentPaymentSheetOptions):
  Promise<{ paymentSheetResult: PaymentSheetResultInterface; paymentIntentClientSecret: string }> {
    // be able to get event of PaymentSheet
    StripeCap.addListener(PaymentSheetEventsEnum.Completed, () => {
      console.log('PaymentSheetEventsEnum.Completed');
    });

    // Connect to your backend endpoint, and get every key.
    const user = this.authProvider.currentUser();
    const { paymentIntent, ephemeralKey, customer } = await Parse.Cloud.run(
      'paymentIntent',
      { customer: user.id, ... options }
    );
    // prepare PaymentSheet with CreatePaymentSheetOption.
    await StripeCap.createPaymentSheet({
      paymentIntentClientSecret: paymentIntent,
      customerId: customer,
      customerEphemeralKeySecret: ephemeralKey,
      enableApplePay: true,
      applePayMerchantId: 'merchant.com.tappjet.tappjet',
      enableGooglePay: true,
      // eslint-disable-next-line @typescript-eslint/naming-convention
      GooglePayIsTesting: true,
      merchantDisplayName: 'Tappjet'
    });

    // present PaymentSheet and get result.
    const result = await StripeCap.presentPaymentSheet();
    console.log('StripeCap.presentPaymentSheet result: ' + result.paymentResult);

    return {paymentSheetResult: result.paymentResult, paymentIntentClientSecret: paymentIntent};
  }
}
