import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { BookingCellViewModel } from './booking-cell-viewmodel';

@Component({
  selector: 'app-booking-cell',
  templateUrl: './booking-cell.component.html',
  styleUrls: ['./booking-cell.component.scss'],
})
export class BookingCellComponent implements OnInit {
  @Input() cellViewModel: BookingCellViewModel;
  @Output() tapViewDetails: EventEmitter<BookingCellViewModel> = new EventEmitter();
  @Output() tapCompletePayment: EventEmitter<BookingCellViewModel> = new EventEmitter();

  constructor() { }

  ngOnInit() {}

  tapViewDetailsOnCell(cellViewModel: BookingCellViewModel) {
    this.tapViewDetails.next(cellViewModel);
  }

  tapCompletePaymentOnCell(cellViewModel: BookingCellViewModel) {
    this.tapCompletePayment.next(cellViewModel);
  }
}
