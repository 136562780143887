import { Component, OnInit, Input } from '@angular/core';
import { ModalController, ToastController } from '@ionic/angular';
import { Airport } from 'src/app/models/airport.class';
import { ParseProvider } from 'src/app/services/parse/parse';
import { environment } from 'src/environments/environment';
import { regions as REGION_MAPPING } from './countries';
import { FlightsFilter } from 'src/app/pages/flights/flights.service';

export interface Region {
  name: string;
  isoCode: string;
}

export interface RegionChoiceViewModel {
  region: Region;
  selected: boolean;
}

@Component({
  selector: 'app-flight-filters',
  templateUrl: './flight-filters.component.html',
  styleUrls: ['./flight-filters.component.scss'],
})
// eslint-disable-next-line @angular-eslint/component-class-suffix
export class FlightFilters implements OnInit {
  @Input() selectedAirports = [];
  @Input() includeOnlySeatDeals = false;
  @Input() selectedRegion: string;
  @Input() isFlightCountEnabled = false;

  regionChoiceViewModels: RegionChoiceViewModel[] = [];
  basicAirports: any[];
  basicAirportFlightCountMap: {[key: string]: number} = {};
  basicAirportBookingResellCountMap: {[key: string]: number} = {};

  constructor(
    public parseProvider: ParseProvider,
    public modalController: ModalController,
    public toastController: ToastController
  ) {}

  get showFilterSeatDealsCheckbox(): boolean {
    return environment.showFilterSeatDealsCheckbox;
  }

  get regionChoices(): Region[] {
    return [{
      name: 'Canada',
      isoCode: 'CA'
    }, {
      name: 'USA',
      isoCode: 'US'
    }, {
      name: 'Europe',
      isoCode: 'EU',
    }];
  };

  ngOnInit() {
    this.regionChoiceViewModels = this.regionChoices.map((ele) => ({
      region: ele,
      selected: (this.selectedRegion === ele.isoCode),
    }));
  }

  async ionViewWillEnter() {
    this.parseProvider.basicAirports.forEach((x) => (x.selected = false));
    const airPorts = this.selectedAirports.map((x) => x.name);
    this.parseProvider.basicAirports
      .filter((x) => airPorts.indexOf(x.name) > -1)
      .forEach((y) => (y.selected = true));
    let basicAirportChildren = [];
    for (const basicAirport of this.parseProvider.basicAirports) {
      basicAirportChildren = basicAirportChildren.concat((basicAirport.airports ?? []).map((ele) => ele.id));
    }
    const configStr = await this.parseProvider.getConfig('emptyLegsFilter');
    const config = JSON.parse(configStr) as FlightsFilter;
    const isFlightsThatHavePrice = config?.isFlightsThatHavePrice ?? false;
    this.basicAirportFlightCountMap = await this.parseProvider.getAirportsFlightCount({
      selectedAirports: basicAirportChildren, isFlightsThatHavePrice
    });
    this.basicAirportBookingResellCountMap =
    await this.parseProvider.getAirportBookingResellCount({
      selectedAirports: basicAirportChildren, isFlightsThatHavePrice
    });
    this.refreshCityList();
  }
  closeModal() {
    this.modalController.dismiss({
      dismissed: true,
      item: false,
    });
  }
  onClickOK() {
    this.selectedAirports = [];
    this.parseProvider.basicAirports
      .filter((x) => x.selected)
      .forEach((ba) => this.selectedAirports.push(ba.name));
    this.modalController.dismiss({
      dismissed: true,
      item: true,
      selectedAirports: this.selectedAirports,
      includeOnlySeatDeals: this.includeOnlySeatDeals,
      selectedRegion: this.selectedRegion,
    });
  }

  onClickRegionChoice(event, value: RegionChoiceViewModel) {
    if (value.region.isoCode === this.selectedRegion) {
      return;
    }
    this.selectedRegion = value.region.isoCode;
    this.parseProvider.basicAirports.forEach((ele) => {
      ele.selected = false;
    });
    for (const choice of this.regionChoiceViewModels) {
      choice.selected = (choice.region.isoCode === value.region.isoCode);
    }
    this.refreshCityList();
  }

  refreshCityList() {
    const selectedRegion = this.regionChoiceViewModels.filter((ele) => ele.selected)[0];
    let basicAirports = [...this.parseProvider.basicAirports];
    if (selectedRegion) {
      basicAirports = basicAirports.filter((ele) => {
        if (selectedRegion.region.isoCode === 'EU') {
          const countries = REGION_MAPPING[selectedRegion.region.isoCode];
          return countries.indexOf(ele.country) >= 0;
        } else {
          return ele.country === selectedRegion.region.isoCode;
        }
      });
    }

    for (const basicAirport of basicAirports) {
      let flightCount = 0;
      let bookingResellCount = 0;
      for (const airport of (basicAirport.airports ?? [])) {
        const airportId = airport.id;
        if (!airportId) {
          continue;
        }
        flightCount += this.basicAirportFlightCountMap[airportId] ?? 0;
        bookingResellCount += this.basicAirportBookingResellCountMap[airportId] ?? 0;
        basicAirport.flightCount = this.includeOnlySeatDeals ? bookingResellCount : flightCount;
      }
    }

    this.basicAirports = basicAirports;
  }

  didChangeIncludeOnlySeatDeals(event) {
    this.refreshCityList();
  }

  async presentToast(msg: string, color: string) {
    const toast = await this.toastController.create({
      message: msg,
      duration: 3000,
      position: 'top',
      color,
    });
    toast.present();
  }
}
