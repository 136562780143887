import moment from 'moment';
import { Operator as FlyEasyOperator } from './flyeasy.model';

export class FlightOperator {
  public id?: string;
  public flyeasyId?: string;
  public name?: string;

  public static createFromParseObject(parseObject: any): FlightOperator {
    const operator = new FlightOperator();
    operator.id = parseObject.id;
    operator.name = parseObject.get('name');
    return operator;
  }

  public static createFromFlyEasyObject(operator: FlyEasyOperator): FlightOperator {
    const obj = new FlightOperator();
    // eslint-disable-next-line no-underscore-dangle
    obj.flyeasyId = operator._id;
    obj.name = operator.name;
    return obj;
  }
}
