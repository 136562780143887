import moment from 'moment';
import { FlightOperator } from './flight-operator.class';
import { Aircraft as FlyeasyAircraft, Operator as FlyeasyOperator } from './flyeasy.model';
import { Airport } from './airport.class';

export class Aircraft {
  public id?: string;
  public operator?: FlightOperator | null;
  public name?: string | null;
  public seats?: number | null;
  public photo?: string | null;

  // Info from fly easy api
  public tail?: string;
  public category?: string;
  public home?: Airport;
  public aircraftUses?: string[];
  public retailRate?: number;
  public currency?: string;
  public quality?: string;
  public yearOfMake?: number;
  public photos?: string[];

  public static createFromParseObject(parseObj: any): Aircraft {
    const obj = new Aircraft();
    obj.id = parseObj.id;
    if (parseObj.get('operator')) {
        obj.operator = FlightOperator.createFromParseObject(parseObj.get('operator'));
    }
    obj.name = parseObj.get('name');
    obj.seats = parseObj.get('seats');
    obj.photo = parseObj.get('photo')?.url();
    obj.tail = parseObj.get('tail');
    obj.category = parseObj.get('category');
    if (parseObj.get('home')) {
      obj.home = Airport.createFromParseObject(parseObj.get('home'));
    }
    obj.aircraftUses = parseObj.get('aircraftUses');
    obj.retailRate = parseObj.get('retailRate');
    obj.currency = parseObj.get('currency');
    obj.quality = parseObj.get('quality');
    obj.yearOfMake = parseObj.get('yearOfMake');
    obj.photos = parseObj.get('photos');

    return obj;
  }

  public static createFromFlyEasyObject({
    aircraft, operator
  }: {
    aircraft: FlyeasyAircraft; operator: FlyeasyOperator;
  }): Aircraft {
    const obj = new Aircraft();
    obj.operator = FlightOperator.createFromFlyEasyObject(operator);
    obj.tail = aircraft.tail;
    obj.seats = aircraft.pax;
    obj.name = aircraft.modelDescription;
    obj.category = aircraft.category;
    obj.home = Airport.createFromFlyEasyObject(aircraft.home);
    obj.aircraftUses = aircraft.aircraftUses;
    obj.retailRate = aircraft.retailRate;
    obj.currency = aircraft.currency;
    obj.quality = aircraft.quality;
    obj.yearOfMake = aircraft.yearOfMake;
    obj.photos = aircraft.aircraftUses;
    return obj;
  }
}
