import { Injectable } from '@angular/core';
import { map } from 'rxjs/operators';

// Parse
import { Parse } from 'parse';

import moment from 'moment';
import * as luxon from 'luxon';

// Constants

import { Flight } from '../../models/flight.class';
import { PurchaseInquiry } from '../../models/purchase-inquiry.calss';
import { Airport } from '../../models/airport.class';
import { environment } from 'src/environments/environment';
import { Membership } from 'src/app/models/membership.class';
import { AuthProvider, User } from '../auth/auth';
import { Guest } from 'src/app/models/guest.class';
import {
  Booking,
  BookingStatus,
  BookingTargetType,
} from 'src/app/models/booking.class';
import { CharterQuoteRequest } from 'src/app/pages/charter-quote-request/charter-request.model';
import { BookingPayment } from 'src/app/models/booking-payment.class';
import { FlightOperator } from 'src/app/models/flight-operator.class';
import { Transaction, TransactionType } from 'src/app/models/transaction.class';
import { BookingResell } from 'src/app/models/booking-resell.class';
import { orQuery } from 'src/app/common/utils';
import { AircraftOption, Leg } from 'src/app/models/flyeasy.model';
import { ParseError, ParseErrorCode } from 'src/app/models/parse-error.interface';
import { NavController } from '@ionic/angular';

export class ListResponse<T> {
  totalCount: number;
  results: T[];
}

export interface GetFlightsResult {
  bookingResells?: BookingResell[];
  flights?: Flight[];
}

@Injectable()
export class ParseProvider {
  // eslint-disable-next-line @typescript-eslint/naming-convention
  public AirportsFrom = [];
  // eslint-disable-next-line @typescript-eslint/naming-convention
  public AirportsTo = [];
  public fillingAirports = false;
  public fillingBasicAirports = false;

  /**
  NewAirport=airports that come through API but are not in Airport table (they get suspended from publishing to app).
  Airport is all airports that can get published to app
  BasicAirports are the ones in the filter (currently we have 8 but just now we are talking about extending this to more airports)
  VicinityAirports are those airports within 50mi of the BasicAirport. They get same treatment as BasicAirport
  All flights in API work off BasicAirport..meaning that if a flight has the BasicAirport (or VicinityAirport) as
  either To Airport or From Airport then it gets displayed.
  E.g., if To Airport is one of the Basic/Vicinity Airports, then the other airport can by ‘any’
  as long as it is recognized in Airports table.
   */
  public basicAirports = [];
  // eslint-disable-next-line @typescript-eslint/naming-convention
  public VicinityAirports = [];
  // eslint-disable-next-line @typescript-eslint/naming-convention
  public Airports = [];
  public fillBasicAirportsPromise: Promise<void>;

  private parseAppId: string = environment.parseAppId;
  private parseJsKey: string = environment.parseJsKey;
  private parseServerUrl: string = environment.parseServerUrl;

  constructor(private auth: AuthProvider,
    private navController: NavController,) {
    this.parseInitialize();
  }

  public uniqueObjects(array: any[]): any[] {
    const result = [];
    const check = new Map();
    for (const item of array) {
      if (!check.has(item.id)) {
        check.set(item.id, true); // set any value to Map
        result.push({
          id: item.id,
          name: item.name,
          icaoCode: item.icaoCode,
        });
      }
    }

    return result.sort((a, b) => (a.name < b.name ? -1 : 1));
  }

  // eslint-disable-next-line @typescript-eslint/naming-convention
  public async getConfig(config_name): Promise<any> {
    try {
      return await Parse.Config.get().then((config) => config.get(config_name));
    } catch (error) {
      const parseError = error as ParseError;
      if (parseError.code === ParseErrorCode.sessionExpiredOrInvalid) {
        await this.auth.signout();
        this.navController.navigateBack('/login');
      }
      throw error;
    }
  }

  public async fillBasicAirports() {
    console.log(`fillBasicAirports`);
    if (this.fillingBasicAirports && this.fillBasicAirportsPromise) {
      return this.fillBasicAirportsPromise;
    }
    this.fillBasicAirportsPromise = new Promise(async (resolve) => {
      this.fillingBasicAirports = true;
      this.basicAirports = [];
      this.VicinityAirports = [];
      this.Airports = [];
      let query = new Parse.Query('BasicAirports');
      query.ascending('d_o');
      query.exists('airport');
      query.limit(2000);
      const res = await this.queryFind(query);
      res.forEach((x) => {
        const airportIds = this.basicAirports.map((ele) => ele.id);
        const airportNames = this.basicAirports.map((ele) => ele.name ?? '');
        const newAirportId = x.get('airport')?.id;
        const newAirportName = x.get('name') ?? '';
        if (newAirportId && airportIds.indexOf(newAirportId) < 0 && airportNames.indexOf(newAirportName) < 0) {
          this.basicAirports.push({
            id: x.get('airport').id,
            name: x.get('name'),
            icaoCode: x.get('icaoCode'),
            country: x.get('iso_country'),
          });
        }
      });

      query = new Parse.Query('VicinityAirports');
      query.limit(1000);
      const resV = await this.queryFind(query);
      resV.forEach((x: any) => {
        const airportIcaos = this.VicinityAirports.map((ele) => ele.icaoCode);
        const newAirportIcao = x.get('vicinityAirportCode');
        if (newAirportIcao && airportIcaos.indexOf(newAirportIcao) < 0) {
          this.VicinityAirports.push({
            name: x.get('rootAirportName'),
            icaoCode: x.get('vicinityAirportCode'),
            country: x.get('iso_country'),
          });
        }
      });

      query = new Parse.Query('Airport');
      query.limit(1000);
      const resA = await this.queryFind(query);
      resA.forEach((x: any) => {
        const airportIds = this.Airports.map((ele) => ele.id);
        const newAirportId = x.id;
        if (newAirportId && airportIds.indexOf(newAirportId) < 0) {
          this.Airports.push({
            id: x.id,
            name: x.get('name'),
            icaoCode: x.get('icaoCode'),
            city: x.get('city'),
          });
        }
      });

      this.basicAirports.forEach((ba) => {
        const aoCodes = this.VicinityAirports.filter(
          (x) => x.name === ba.name && x.country === ba.country
        ).map((c) => c.icaoCode);
        ba.airports = this.Airports.filter(
          (x) => aoCodes.indexOf(x.icaoCode) !== -1 || x.icaoCode === ba.icaoCode ||
          ((ba.icaoCode as string).replace(' ', '').split(',').indexOf(x.icaoCode) !== -1)
        );
      });
      this.fillingBasicAirports = false;
      resolve();
    });
    return this.fillBasicAirportsPromise;
  }

  public async getAirportsFlightCount(params: {
    selectedAirports?: string[];
    isFlightsThatHavePrice?: boolean;}): Promise<{[key: string]: number}> {
    const result = await this.runParseCloudFunction('getAirportsFlightCount', params);
    return result ?? {};
  }

  public async getAirportBookingResellCount(params: {
    selectedAirports?: string[];
    isFlightsThatHavePrice?: boolean;}): Promise<{[key: string]: number}> {
    const result = await this.runParseCloudFunction('getAirportBookingResellCount', params);
    return result ?? {};
  }

  public async getFlights({
      selectedAirports,
      operatorId,
      operatorNames,
      isSeatDeals,
      isFlightDeals,
      isFlightsWithBookingResellsSoldOut,
      isFlightsThatHavePrice,
      isAvailableForSale,
      regions,
      offset, limit
    }: {
      selectedAirports?: any;
      operatorId?: string;
      operatorNames?: string[];
      isSeatDeals?: boolean;
      isFlightDeals?: boolean;
      isFlightsWithBookingResellsSoldOut?: boolean;
      isFlightsThatHavePrice?: boolean;
      isAvailableForSale?: boolean;
      regions?: string[];
      offset?: number; limit?: number;
    }
  ): Promise<GetFlightsResult> {
    const result = await this.runParseCloudFunction('getFlightItems', {
      selectedAirports,
      isSeatDeals,
      isFlightDeals: isFlightDeals ?? true,
      isFlightsWithBookingResellsSoldOut: isFlightsWithBookingResellsSoldOut ?? false,
      isAvailableForSale: isAvailableForSale ?? true,
      isFlightsThatHavePrice,
      operatorId,
      operatorNames,
      regions,
      offset: offset ?? 0,
      limit: limit ?? 20,
    });
    // console.log('ParseProvider getFlights result*', result);
    let bookingResells: BookingResell[] = [];
    const bookingResellParseObjs = result?.bookingResells ?? [];
    if (bookingResellParseObjs && bookingResellParseObjs.length > 0) {
      bookingResells = bookingResellParseObjs.map((ele) => BookingResell.createFromParseObject(ele));
    }

    let flights: Flight[] = [];
    const flightParseObjs = result?.flights;
    if (flightParseObjs && flightParseObjs.length > 0) {
      flights = flightParseObjs.map((ele) => Flight.createFromParseObject(ele));
    }

    return { bookingResells, flights };
  }

  public async fetchFlight(flightId: string): Promise<Flight> {
    // eslint-disable-next-line @typescript-eslint/naming-convention
    const FlightParse = Parse.Object.extend('Flight');
    const query = new Parse.Query(FlightParse);
    query.include([
      'toAirport',
      'fromAirport',
      'aircraft',
      'aircraft.operator',
    ]);
    const parseObj = await this.queryGet(query, flightId);
    const flight = Flight.createFromParseObject(parseObj);
    return flight;
  }

  public async fetchOperator(operatorId: string): Promise<FlightOperator> {
    // eslint-disable-next-line @typescript-eslint/naming-convention
    const Operator = Parse.Object.extend('Operator');
    let operatorParse = new Operator();
    operatorParse.id = operatorId;
    operatorParse = await this.fetchObj(operatorParse);
    const operatorObj = FlightOperator.createFromParseObject(operatorParse);
    return operatorObj;
  }

  public getPurchaseInquiries(
    offset: number = 0,
    limit: number = 20
  ): Promise<any> {
    return this.getObjects('PurchaseInquiry', offset, limit);
  }

  public getTimezones(offset: number = 0, limit: number = 20): Promise<any> {
    return this.getObjects('Timezone', offset, limit, null, 'name');
  }

  public getGuests(
    hostMembershipId: string,
    offset: number = 0,
    limit: number = 20
  ): Promise<any> {
    return this.getObjects(
      'Guest',
      offset,
      limit,
      null,
      'firstName',
      (query) => {
        // eslint-disable-next-line @typescript-eslint/naming-convention
        const MembershipObject = Parse.Object.extend('Membership');
        const subQuery = new Parse.Query(MembershipObject);
        subQuery.equalTo('objectId', hostMembershipId);
        query.matchesQuery('hostMembership', subQuery);
        return query;
      }
    );
  }

  public getAirports(offset: number = 0, limit: number = 1000): Promise<any> {
    return this.getObjects('Airport', offset, limit, null, 'name');
  }

  public getNonMembers(offset: number = 0, limit: number = 1000): Promise<any> {
    return this.getObjects('User', offset, limit, null, 'name', (query) => {
      const accountHolderQuery = new Parse.Query('Membership');
      query.doesNotMatchKeyInQuery('this', 'accountHolder', accountHolderQuery);
      query.notEqualTo('isAdmin', true);
      return query;
    });
  }

  public getFlightActiveBookings(
    flightId: string,

    offset: number = 0,
    limit: number = 20
  ): Promise<any> {
    return this.getObjects(
      'Booking',
      offset,
      limit,
      null,
      'createdAt',
      (query) => {
        const flightQuery = new Parse.Query('Flight');
        flightQuery.equalTo('objectId', flightId);
        query.matchesQuery('flight', flightQuery);

        query.notContainedIn('status', ['pending', 'deleted', 'unbooked']);
        return query;
      }
    );
  }

  public getActiveBookings(
    userId: string,
    offset: number = 0,
    limit: number = 20
  ): Promise<any> {
    return this.getObjects(
      'Booking',
      offset,
      limit,
      null,
      'createdAt',
      (query) => {
        const flightQuery = new Parse.Query('Flight');
        flightQuery.greaterThan('flightDateTime', new Date());
        flightQuery.notEqualTo('isDeleted', true);
        query.matchesQuery('flight', flightQuery);

        const memberQuery = new Parse.Query('_User');
        memberQuery.equalTo('objectId', userId);
        query.matchesQuery('member', memberQuery);
        query.descending('createdAt');
        // query.containedIn('status', ['pending', 'waitlisted', 'confirmed']);

        query.notEqualTo('targetType', BookingTargetType.quotingFlightLeg);

        return query;
      }
    );
  }

  async getQueryOperator(operatorId, operatorNames) {
    // eslint-disable-next-line @typescript-eslint/naming-convention
    const ObjectType = Parse.Object.extend('Flight');

    // Query operator
    const queriesOperator = [];
    const queryByOperatorId = new Parse.Query(ObjectType);
    if (operatorId) {
      const aircraftQuery = new Parse.Query('Aircraft');
      // eslint-disable-next-line @typescript-eslint/naming-convention
      const Operator = Parse.Object.extend('Operator');
      const operator = new Operator();
      operator.id = operatorId;
      aircraftQuery.equalTo('operator', operator);
      queryByOperatorId.matchesQuery('aircraft', aircraftQuery);
      queriesOperator.push(queryByOperatorId);
    }

    const queryByOperatorName = new Parse.Query(ObjectType);
    if (operatorId) {
      try {
        const operator = await this.fetchOperator(operatorId);
        const operatorName = operator?.name;
        if ((operatorName?.length ?? 0) > 0) {
          queryByOperatorName.equalTo('operator', operatorName);
          queriesOperator.push(queryByOperatorName);
        }
      } catch (err) {
        throw new Error(`Retrieve operator information failed: ${err}`);
      }
    }


    const queryByOperatorNames = new Parse.Query(ObjectType);
    if (operatorNames && operatorNames.length > 0) {
      queryByOperatorNames.containedIn('operator', operatorNames);
      queriesOperator.push(queryByOperatorNames);
    }

    let operatorQuery;
    if (queriesOperator.length > 1) {
      operatorQuery = Parse.Query.or(queriesOperator);
    } else if (queriesOperator.length > 0) {
      operatorQuery = queriesOperator[0];
    }
    return operatorQuery;
  }

  /**
   * selectedAirports: Airport parse objects
   */
  async queryGetLegs(selectedAirports: any[], operatorId: string | undefined, operatorNames: string[]) {
    // eslint-disable-next-line @typescript-eslint/naming-convention
    const ObjectType = Parse.Object.extend('Leg');
    const query1 = new Parse.Query(ObjectType);
    // query1.notEqualTo('aircraft', null); // TODO: release this check when flyeasy api update is ready.

    if (selectedAirports.length > 0) {
      query1.containedIn('origin', selectedAirports);
    }

    const query2 = new Parse.Query(ObjectType);
    if (selectedAirports.length > 0) {
      query2.containedIn('destination', selectedAirports);
    }
    const operatorQuery = await this.getQueryOperator(operatorId, operatorNames);

    let mainQuery = null;
    if (operatorQuery) {
      mainQuery = Parse.Query.and(Parse.Query.or(query1, query2), operatorQuery);
    } else {
      mainQuery = Parse.Query.or(query1, query2);
    }

    mainQuery.greaterThan('departure', new Date());
    return mainQuery;
  }

  public async getMemberAllBookings(
    userId: string = null,
    operatorId: string | null = null,
    hidePastBookings: boolean = false,
    offset: number = 0,
    limit: number = 20,
    parentBookingId: string = undefined,
  ): Promise<any> {
    const operator = (operatorId) ? await this.fetchOperator(operatorId) : null;

    const quotingFlightLegQuery = await this.queryGetLegs([], operatorId, []);
    if (hidePastBookings) {
      quotingFlightLegQuery.greaterThan('departure', new Date());
    }
    const matchQuotingFlightLegQuery = new Parse.Query(Booking);
    matchQuotingFlightLegQuery.matchesQuery('leg', quotingFlightLegQuery);

    return await this.getObjects('Booking',
      offset,
      limit,
      null,
      null, (query) => {

        if (userId) {
          const memberQuery = new Parse.Query('_User');
          memberQuery.equalTo('objectId', userId);
          query.matchesQuery('member', memberQuery);
        }

        if (parentBookingId) {
          // eslint-disable-next-line @typescript-eslint/naming-convention, @typescript-eslint/no-shadow
          const Booking = Parse.Object.extend('Booking');
          const parentBooking = new Booking();
          parentBooking.id = parentBookingId;
          query.equalTo('parent', parentBooking);
        }

        // Query operator id
        const operatorQueryId = new Parse.Query('Flight');
        if (operatorId) {
          // eslint-disable-next-line @typescript-eslint/naming-convention
          const Operator = Parse.Object.extend('Operator');
          const newOperator = new Operator();
          newOperator.id = operatorId;
          const aircraftQuery = new Parse.Query('Aircraft');
          aircraftQuery.equalTo('operator', newOperator);
          operatorQueryId.matchesQuery('aircraft', aircraftQuery);
        }
        // Query operator name
        const operatorQueryName = new Parse.Query('Flight');
        if (operator) {
          try {
            if (operator?.name && operator?.name.length > 0) {
              operatorQueryName.equalTo('operator', operator.name);
            }
          } catch (err) {
            throw new Error(`Retrieve operator information failed: ${err}`);
          }
        }
        const operatorQuery = Parse.Query.or(
          operatorQueryId,
          operatorQueryName
        );

        // Match flight query
        const flightDateTimeQuery = new Parse.Query('Flight');
        if (hidePastBookings) {
          flightDateTimeQuery.greaterThan('flightDateTime', new Date());
        }
        let flightQuery = null;
        if (operatorId) {
          flightQuery = Parse.Query.and(operatorQuery, flightDateTimeQuery);
        } else {
          flightQuery = flightDateTimeQuery;
        }
        const matchFlightQuery = new Parse.Query(Booking);
        matchFlightQuery.matchesQuery('flight', flightQuery);

        // Match quoting flight query
        const quotingFlightDateTimeQuery = new Parse.Query('QuotingFlight');
        if (hidePastBookings) {
          quotingFlightDateTimeQuery.greaterThan('departureDate', new Date());
        }
        let quotingFlightQuery = null;
        if (operatorId) {
          quotingFlightQuery = Parse.Query.and(operatorQuery, quotingFlightDateTimeQuery);
        } else {
          quotingFlightQuery = quotingFlightDateTimeQuery;
        }
        const matchQuotingFlightQuery = new Parse.Query(Booking);
        matchQuotingFlightQuery.matchesQuery('quotingFlight', quotingFlightQuery);

        orQuery(query, [matchFlightQuery, matchQuotingFlightQuery, matchQuotingFlightLegQuery]);

        query.notEqualTo('targetType', BookingTargetType.quotingFlightLeg);

        query.containedIn('status', [
          BookingStatus.Pending,
          BookingStatus.PaymentPending,
          BookingStatus.Waitlisted,
          BookingStatus.Confirmed,
          BookingStatus.Cancelled,
        ]);

        query.include([
          'flight',
          'flight.aircraft',
          'flight.fromAirport',
          'flight.toAirport',
          'flight.aircraft.operator',
          'quotingFlight',
          'quotingFlight.aircraft',
          'quotingFlight.fromAirport',
          'quotingFlight.toAirport',
          'quotingFlight.operator',
          'quotingFlight.legs',
          'payment',
          'payment.discount',
          'payments',
          'member',
          'member.membership',
          'guest',
        ]);

        query.descending('createdAt');

        console.log('Flight query: ' + JSON.stringify(query));

        return query;
      }
    );
  }

  public async getChildrenBookings(
    userId: string = null,
    hidePastBookings: boolean = false,
    parentBookingId: string = undefined,
    offset: number = 0,
    limit: number = 20,
  ): Promise<any> {
    console.log(`getChildrenBookings`);
    const result = await this.findBooking(parentBookingId);
    if (!result) {
      return [];
    }
    const parentBooking = Booking.createFromParseObject(result);

    const childrenMain = (parentBooking.children ?? []).filter(child => {
      let isInMainLegs = false;
      for (const leg of parentBooking.quotingFlight?.mainLegs ?? []) {
        if (leg?.id === child.leg?.id) {
          isInMainLegs = true;
          break;
        }
      }
      return isInMainLegs;
    }).map((child) => Booking.createFromParseObject(child.parseObject));
    return childrenMain;
  }

  public getMembers(offset: number = 0, limit: number = 20): Promise<any> {
    return this.getObjects(
      'Membership',
      offset,
      limit,
      'accountHolder',
      'expiryDate',
      null
    );
  }

  public async getAircrafts(operatorId: string | null,
    offset: number = 0, limit: number = 100): Promise<any> {
    // Query operator
    try {
      return this.getObjects('Aircraft', offset, limit, null, 'name', (query) => {
        if (operatorId) {
          // eslint-disable-next-line @typescript-eslint/naming-convention
          const Operator = Parse.Object.extend('Operator');
          const operator = new Operator();
          operator.id = operatorId;
          query.equalTo('operator', operator);
        }
        query.notEqualTo('isDeleted', true);
        return query;
      });
    } catch (err) {
      throw new Error(`Retrieve operator information failed: ${err}`);
    }
  }

  public async deleteAircraft(id: string) {
    const aircraft = await this.findObject('Aircraft', id);
    aircraft.set('isDeleted', true);
    return await aircraft.save(null);
  }

  public getOperators(offset: number = 0, limit: number = 1000): Promise<any> {
    return this.getObjects('Operator', offset, limit, null, 'name');
  }

  // todo: check offset with total count of object.
  // return empty if offset is equal or greater than total count.
  // total count can be added in return result.
  // so this logic can also be checked by client.

  public getObjects(
    className: string,
    offset: number = 0,
    limit: number = 40,
    includeKeys: string | string[] = null,
    sortByKey: string = null,
    queryFilters: any = null
  ): Promise<any> {
    return new Promise((resolve, reject) => {
      setTimeout(() => {
        // eslint-disable-next-line @typescript-eslint/naming-convention
        const ObjectType = Parse.Object.extend(className);
        let query = new Parse.Query(ObjectType);

        if (queryFilters) {
          query = queryFilters(query);
        }

        this.queryCount(query).then(
          (totalCount) => {
            // todo: check offset with total count of object.

            query.skip(offset);
            query.limit(limit);

            if (includeKeys) {
              query.include(includeKeys);
            }

            if (sortByKey) {
              query.ascending(sortByKey);
            }
            this.queryFind(query).then(
              (results) => {
                resolve({ totalCount, results });
              },
              (error) => {
                console.error(error);
                reject(error);
              }
            );
          },
          async (err) => {
            console.error(err);
            // Unauthrized error, token exired.
            // logout.
            const parseError = err as ParseError;
            if (parseError.code === ParseErrorCode.sessionExpiredOrInvalid) {
              await this.auth.signout();
              this.navController.navigateBack('/login');
            }
          }
        );
      }, 0);
    });
  }

  public async updateFlightPrice(
    flightParseObject: any,
    price: number,
    currrency: string
  ) {
    flightParseObject.set('price', price);
    flightParseObject.set('currrency', currrency);
    await flightParseObject.save(null);
  }

  public async postFlight(flightInfo: Flight): Promise<any> {
    // eslint-disable-next-line @typescript-eslint/naming-convention
    const FlightObject = Parse.Object.extend('Flight');
    let flight = flightInfo.parseObject;
    if (!flight) {
      flight = new FlightObject();
    }

    if (flightInfo.id !== 'new') {
      flight.id = flightInfo.id;
      flight = flightInfo.parseObject;
    }

    flight.set('fromCity', flightInfo.fromCity);
    flight.set('toCity', flightInfo.toCity);
    flight.set('wholeFlightPrice', flightInfo.wholeFlightPrice);
    flight.set('wholeFlightPriceRetail', flightInfo.wholeFlightPriceRetail);

    flight.set('fromAirport', {
      // eslint-disable-next-line @typescript-eslint/naming-convention
      __type: 'Pointer',
      className: 'Airport',
      objectId: flightInfo.fromCityId,
    });
    flight.set('toAirport', {
      // eslint-disable-next-line @typescript-eslint/naming-convention
      __type: 'Pointer',
      className: 'Airport',
      objectId: flightInfo.toCityId,
    });

    const local = luxon.DateTime.fromISO(flightInfo.flightDateTime);
    const rezoned = local.setZone(flightInfo.fromCityTimezone, {
      keepLocalTime: false,
    });

    const rezoneFlightTimeISO = rezoned.toISO();
    flight.set('flightDateTime', new Date(rezoneFlightTimeISO));
    flight.set('flightDuration', flightInfo.flightDuration);
    const arrivalDt = moment(new Date(rezoneFlightTimeISO))
      .add(flightInfo.flightDuration, 'minutes')
      .toDate();

    flight.set('arrivalDateTime', arrivalDt);

    flight.set('flightTimeTBD', flightInfo.flightTimeTBD);
    if (flightInfo.aircraftId) {
      flight.set('aircraft', {
        // eslint-disable-next-line @typescript-eslint/naming-convention
        __type: 'Pointer',
        className: 'Aircraft',
        objectId: flightInfo.aircraftId,
      });
    }
    flight.set('totalSeats', flightInfo.totalSeats);
    flight.set('availableSeats', flightInfo.availableSeats);
    flight.set('price', flightInfo.price);
    flight.set('currency', flightInfo.currency);
    flight.set('isAvailableForSale', flightInfo.isAvailableForSale);

    return flight.save(null, {
      // eslint-disable-next-line prefer-arrow/prefer-arrow-functions
      success(result) {
        return result;
      },
      // eslint-disable-next-line prefer-arrow/prefer-arrow-functions
      error(result, error) {
        console.log(error);
        return error;
      },
    });
  }
  public addFlight(flightInfo): Promise<any> {
    // eslint-disable-next-line @typescript-eslint/naming-convention
    const FlightObject = Parse.Object.extend('Flight');

    const flight = new FlightObject();

    flight.set('fromCity', flightInfo.fromCity);
    flight.set('toCity', flightInfo.toCity);
    flight.set('flightDateTime', flightInfo.flightDateTime);

    flight.set('flightDate', flightInfo.flightDate);
    flight.set('flightTime', flightInfo.flightTime);
    flight.set('flightTimeTBD', flightInfo.flightTimeTBD);
    flight.set('aircraft', {
      // eslint-disable-next-line @typescript-eslint/naming-convention
      __type: 'Pointer',
      className: 'Aircraft',
      objectId: flightInfo.aircraftId,
    });
    flight.set('totalSeats', flightInfo.totalSeats);
    flight.set('availableSeats', flightInfo.availableSeats);

    return flight.save(null, {
      // eslint-disable-next-line prefer-arrow/prefer-arrow-functions
      success(result) {
        return result;
      },
      // eslint-disable-next-line prefer-arrow/prefer-arrow-functions
      error(result, error) {
        console.log(error);
        return error;
      },
    });
  }

  public deleteFlight(id: string): Promise<any> {
    return this.findObject('Flight', id).then((flight: Parse.Object) => {
      flight.set('isDeleted', true);

      return flight.save(null, {
        // eslint-disable-next-line prefer-arrow/prefer-arrow-functions
        success(result) {
          return result;
        },
        // eslint-disable-next-line prefer-arrow/prefer-arrow-functions
        error(result, error) {
          console.log(error);
          return error;
        },
      });
    });
  }

  public addAirport(newAirport): Promise<any> {
    // eslint-disable-next-line @typescript-eslint/naming-convention
    const AirportObject = Parse.Object.extend('Airport');

    const airport = new AirportObject();

    airport.set('name', newAirport.name);
    airport.set('icaoCode', newAirport.icaoCode);

    return airport.save(null, {
      // eslint-disable-next-line prefer-arrow/prefer-arrow-functions
      success(result) {
        return result;
      },
      // eslint-disable-next-line prefer-arrow/prefer-arrow-functions
      error(result, error) {
        console.log(error);
        return error;
      },
    });
  }

  public findFlight(id): Promise<Flight> {
    return this.findObject('Flight', id, [
      'aircraft',
      'aircraft.operator',
      'toAirport',
      'fromAirport',
    ]).then((x) => {
      if (x) {
        return Flight.createFromParseObject(x);
      } else {
        return null;
      }
    });
  }

  public findOperator(id): Promise<any> {
    return this.findObject('Operator', id);
  }

  public findAirport(id): Promise<any> {
    return this.findObject('Airport', id);
  }

  public findAircraft(id): Promise<any> {
    return this.findObject('Aircraft', id);
  }

  public findUser(id): Promise<any> {
    return this.runParseCloudFunction('fetchUser', { id });
    // return this.findObject('User', id);
  }

  public findGuest(id): Promise<any> {
    return this.findObject('Guest', id);
  }

  public findBooking(id): Promise<any> {
    return this.findObject('Booking', id, [
      'flight',
      'flight.aircraft',
      'flight.fromAirport',
      'flight.toAirport',
      'flight.aircraft.operator',
      'quotingFlight',
      'quotingFlight.aircraft',
      'quotingFlight.fromAirport',
      'quotingFlight.toAirport',
      'quotingFlight.operator',
      'quotingFlight.legs',
      'quotingFlight.mainLegs',
      'quotingFlight.trivialLegs',
      'payment',
      'payment.discount',
      'member',
      'member.membership',
      'guest',
      'bookingResell',
      'bookingResell.booking',
      'bookingResell.booking.flight',
      'children',
      'parent',
      'leg',
      'leg.origin',
      'leg.destination',
    ]);
  }

  public async findBookingResell(id: string) {
    const parseObj = await this.findObject('BookingResell', id, [
      'booking',
      'booking.flight',
      'booking.flight.aircraft',
      'booking.flight.fromAirport',
      'booking.flight.toAirport',
      'booking.flight.aircraft.operator',
      'booking.quotingFlight',
      'booking.quotingFlight.aircraft',
      'booking.quotingFlight.fromAirport',
      'booking.quotingFlight.toAirport',
      'booking.quotingFlight.operator',
      'booking.quotingFlight.legs',
      'quotingFlight.mainLegs',
      'quotingFlight.trivialLegs',
      'booking.payment',
      'booking.payment.discount',
      'booking.member',
      'booking.member.membership',
      'booking.guest',
      'booking.leg',
      'booking.leg.origin',
      'booking.leg.destination',
    ]);
    if (!parseObj) {
      return null;
    }
    const obj = BookingResell.createFromParseObject(parseObj);
    return obj;
  }

  public async deleteBookingResell(id) {
    const bookingResell = await this.findBookingResell(id);
    const parseObj = bookingResell.parseObject;
    await parseObj.destroy({});
  }

  public findMember(id): Promise<any> {
    return this.findObject('Membership', id, [
      'accountHolder',
      'jointMember1',
      'jointMenber2',
      'jointMember3',
    ]);
  }

  public async findObject(
    className: string,
    id: string,
    includeKeys: string[] | string = null
  ): Promise<any> {
    const query = new Parse.Query(className);
    query.equalTo('objectId', id);
    if (includeKeys) {
      query.include(includeKeys);
    }

    const result = await query.first();
    return result;
  }

  public postAirport(airportInfo: Airport): Promise<any> {
    // eslint-disable-next-line @typescript-eslint/naming-convention
    const AirportObject = Parse.Object.extend('Airport');

    const airport = new AirportObject();

    if (airportInfo.id !== 'new') {
      airport.id = airportInfo.id;
    }

    airport.set('name', airportInfo.name);
    airport.set('icaoCode', airportInfo.icaoCode);
    airport.set('timezone', airportInfo.timezone);

    return airport.save(null, {
      // eslint-disable-next-line prefer-arrow/prefer-arrow-functions
      success(result) {
        return result;
      },
      // eslint-disable-next-line prefer-arrow/prefer-arrow-functions
      error(result, error) {
        console.log(error);
        return error;
      },
    });
  }

  public async postBookingResell(bookingResell: BookingResell) {
    const parseObj = this.createParseObjFromBookingResell(bookingResell);
    const newObject = await parseObj.save(null);
    const newBookingResell = BookingResell.createFromParseObject(newObject);
    return newBookingResell;
  }

  public async postBookingResells(bookingResells: BookingResell[]) {
    let parseObjs = bookingResells.map((bookingResell) => this.createParseObjFromBookingResell(bookingResell));
    parseObjs = await Parse.Object.saveAll(parseObjs);
    const newBookingResells = parseObjs.map((parseObj) => BookingResell.createFromParseObject(parseObj));
    return newBookingResells;
  }

  public postMembership(membershipInfo: Membership): Promise<any> {
    // eslint-disable-next-line @typescript-eslint/naming-convention
    const MembershipObject = Parse.Object.extend('Membership');

    const membership = new MembershipObject();

    if (membershipInfo.id) {
      membership.id = membershipInfo.id;
    }

    membership.set('isCompanyType', membershipInfo.isCompanyType);
    membership.set('expiryDate', moment(membershipInfo.expiryDate).toDate());

    membership.set('accountHolder', {
      // eslint-disable-next-line @typescript-eslint/naming-convention
      __type: 'Pointer',
      className: '_User',
      objectId: membershipInfo.accountHolder.id,
    });

    if (membershipInfo.jointMember1 && membershipInfo.jointMember1.id) {
      membership.set('jointMember1', {
        // eslint-disable-next-line @typescript-eslint/naming-convention
        __type: 'Pointer',
        className: '_User',
        objectId: membershipInfo.jointMember1.id,
      });
    }

    if (membershipInfo.jointMember2 && membershipInfo.jointMember2.id) {
      membership.set('jointMember2', {
        // eslint-disable-next-line @typescript-eslint/naming-convention
        __type: 'Pointer',
        className: '_User',
        objectId: membershipInfo.jointMember2.id,
      });
    }

    if (membershipInfo.jointMember3 && membershipInfo.jointMember3.id) {
      membership.set('jointMember3', {
        // eslint-disable-next-line @typescript-eslint/naming-convention
        __type: 'Pointer',
        className: '_User',
        objectId: membershipInfo.jointMember3.id,
      });
    }

    // todo: save guests list

    // console.log('about to save membership', membership);
    return membership.save();
  }

  public postAircraft(aircraftInfo): Promise<any> {
    // eslint-disable-next-line @typescript-eslint/naming-convention
    const Aircraft = Parse.Object.extend('Aircraft');

    const aircraft = new Aircraft();

    if (aircraftInfo.id !== 'new') {
      aircraft.id = aircraftInfo.id;
    }

    aircraft.set('name', aircraftInfo.name);
    aircraft.set('tailNumber', aircraftInfo.tailNumber);
    aircraft.set('seats', aircraftInfo.seats);

    aircraft.set('operator', {
      // eslint-disable-next-line @typescript-eslint/naming-convention
      __type: 'Pointer',
      className: 'Operator',
      objectId: aircraftInfo.operatorId,
    });

    if (aircraftInfo.newPhotoFile) {
      const parseFile = new Parse.File(
        aircraftInfo.name,
        aircraftInfo.newPhotoFile
      );
      parseFile.save();

      aircraft.set('photo', parseFile);
    }

    // console.log('about to save aircraft');
    return aircraft.save(null, {
      // eslint-disable-next-line prefer-arrow/prefer-arrow-functions
      success(result) {
        return result;
      },
      // eslint-disable-next-line prefer-arrow/prefer-arrow-functions
      error(result, error) {
        console.log(error);
        return error;
      },
    });
  }

  public postOperator(operatorInfo): Promise<any> {
    // eslint-disable-next-line @typescript-eslint/naming-convention
    const Operator = Parse.Object.extend('Operator');

    const operator = new Operator();

    if (operatorInfo.id !== 'new') {
      operator.id = operatorInfo.id;
    }
    operator.set('name', operatorInfo.name);

    return operator.save(null, {
      // eslint-disable-next-line prefer-arrow/prefer-arrow-functions
      success(result) {
        return result;
      },
      // eslint-disable-next-line prefer-arrow/prefer-arrow-functions
      error(result, error) {
        console.log(error);
        return error;
      },
    });
  }

  public postGuest(guest: Guest): Promise<any> {
    // eslint-disable-next-line @typescript-eslint/naming-convention
    const GuestObject = Parse.Object.extend('Guest');

    const guestObj = new GuestObject();

    if (guest.id !== 'new') {
      guestObj.id = guest.id;
    }
    guestObj.set('firstName', guest.firstName);
    guestObj.set('lastName', guest.lastName);
    guestObj.set('email', guest.email);
    guestObj.set('phoneNumber', guest.phoneNumber);
    guestObj.set('birthDate', guest.birthDate);
    guestObj.set('citizenship', guest.citizenship);
    guestObj.set('hostMembership', {
      // eslint-disable-next-line @typescript-eslint/naming-convention
      __type: 'Pointer',
      className: 'Membership',
      objectId: guest.hostMembershipId,
    });

    return guestObj.save(null, {
      // eslint-disable-next-line prefer-arrow/prefer-arrow-functions
      success(result) {
        return result;
      },
      // eslint-disable-next-line prefer-arrow/prefer-arrow-functions
      error(result, error) {
        console.log(error);
        return error;
      },
    });
  }

  public async postBooking(booking: Booking) {
    // TODO: check booking cut off time, 8pm the day before
    // TODO: check total number of active booking
    // TODO: check membership expiry date.
    // TODO: calcuate available seats on flight. (flyeasy will go by flyeasy fly number)
    // eslint-disable-next-line @typescript-eslint/naming-convention
    const BookingObject = Parse.Object.extend('Booking');
    const bookingObj = new BookingObject();

    if (booking.id) {
      bookingObj.id = booking.id;
    }
    if (booking.flight?.id) {
      bookingObj.set('flight', {
        // eslint-disable-next-line @typescript-eslint/naming-convention
        __type: 'Pointer',
        className: 'Flight',
        objectId: booking.flight.id,
      });
    }
    if (booking.bookingResell?.id) {
      bookingObj.set('bookingResell', {
        // eslint-disable-next-line @typescript-eslint/naming-convention
        __type: 'Pointer',
        className: 'BookingResell',
        objectId: booking.bookingResell?.id,
      });
    }
    console.log(`Here`);
    if (booking.quotingFlight?.id) {
      bookingObj.set('quotingFlight', {
        // eslint-disable-next-line @typescript-eslint/naming-convention
        __type: 'Pointer',
        className: 'QuotingFlight',
        objectId: booking.quotingFlight?.id,
      });
    }
    if (booking.leg?.id) {
      bookingObj.set('leg', {
        // eslint-disable-next-line @typescript-eslint/naming-convention
        __type: 'Pointer',
        className: 'Leg',
        objectId: booking.leg?.id,
      });
    }
    bookingObj.set('member', {
      // eslint-disable-next-line @typescript-eslint/naming-convention
      __type: 'Pointer',
      className: '_User',
      objectId: booking.member.id,
    });

    if (booking.bookingPrice > 1) {
      if (booking.payment?.id) {
        bookingObj.set('payment', {
          // eslint-disable-next-line @typescript-eslint/naming-convention
          __type: 'Pointer',
          className: 'BookingPayment',
          objectId: booking.payment.id,
        });
      }

      console.log(`HEre`);
      if (booking.payments) {
        const paymentParseObjs: any[] = [];
        for (const payment of booking.payments) {
          if (payment.parseObject) {
            paymentParseObjs.push(payment.parseObject);
          }
        }
        bookingObj.set('payments', paymentParseObjs);
      }
    }

    bookingObj.set('numberOfSeats', +booking.numberOfSeats);
    bookingObj.set('numberOfPassengers', +booking.numberOfPassengers);
    bookingObj.set('status', booking.status || BookingStatus.Deleted);

    bookingObj.set('paymentReceived', booking.paymentReceived);
    bookingObj.set('bookingPrice', booking.bookingPrice);
    bookingObj.set('currency', booking.currency);

    bookingObj.set('bookingType', booking.bookingType);
    bookingObj.set('targetType', booking.targetType);

    bookingObj.set('fullName', booking.fullName);
    bookingObj.set('email', booking.email);
    bookingObj.set('phoneNumber', booking.phoneNumber);
    bookingObj.set('address', booking.address);
    bookingObj.set('city', booking.city);
    bookingObj.set('state', booking.state);
    bookingObj.set('country', booking.country);
    bookingObj.set('zip', booking.zip);
    bookingObj.set('paymentType', booking.paymentType);

    let res = await bookingObj.save();

    // Save map booking
    if (booking.payments) {
      const paymentParseObjs: any[] = [];
      for (const payment of booking.payments) {
        if (payment.parseObject) {
          payment.parseObject.set('booking', res);
          paymentParseObjs.push(payment.parseObject);
        }
      }
      await Parse.Object.saveAll(paymentParseObjs);
    }

    res = await res.fetch({include: [
      'flight',
      'flight.aircraft',
      'flight.fromAirport',
      'flight.toAirport',
      'flight.aircraft.operator',
      'quotingFlight',
      'quotingFlight.aircraft',
      'quotingFlight.fromAirport',
      'quotingFlight.toAirport',
      'quotingFlight.operator',
      'quotingFlight.legs',
      'quotingFlight.mainLegs',
      'quotingFlight.trivialLegs',
      'payment',
      'payment.discount',
      'member',
      'member.membership',
      'guest',
      'leg',
      'leg.origin',
      'leg.destination',
      'children',
    ]});

    const newBookingObj = Booking.createFromParseObject(res);

    return newBookingObj;
  }
  public postUser(user: User): Promise<any> {
    const data = {
      ...user,
      membership: undefined,
      membershipId: user.membership.id,
    };
    if (user.id) {
      return this.runParseCloudFunction('modifyUser', data);
    }
    return this.runParseCloudFunction('createUser', data);
  }

  public addPurchaseInquiry(purchaseInquiry: PurchaseInquiry): Promise<any> {
    // eslint-disable-next-line @typescript-eslint/naming-convention
    const CallbackReqeustObject = Parse.Object.extend('PurchaseInquiry');

    const newObject = new CallbackReqeustObject();

    newObject.set('firstName', purchaseInquiry.firstName);
    newObject.set('lastName', purchaseInquiry.lastName);
    newObject.set('phone', purchaseInquiry.phone);
    newObject.set('email', purchaseInquiry.email);
    newObject.set('city', purchaseInquiry.city);
    if (purchaseInquiry.membershipType) {
      newObject.set('membershipType', purchaseInquiry.membershipType);
    }

    if (purchaseInquiry.preferredDestinations) {
      newObject.set(
        'preferredDestinations',
        purchaseInquiry.preferredDestinations
      );
    }

    if (purchaseInquiry.specialRequirements) {
      newObject.set('specialRequirements', purchaseInquiry.specialRequirements);
    }

    return newObject.save(null, {
      // eslint-disable-next-line prefer-arrow/prefer-arrow-functions
      success(result) {
        return result;
      },
      // eslint-disable-next-line prefer-arrow/prefer-arrow-functions
      error(result, error) {
        console.log(error);
        return error;
      },
    });
  }

  public addCharterQuoteRequest(
    charterQuoteRequest: CharterQuoteRequest
  ): Promise<any> {
    // eslint-disable-next-line @typescript-eslint/naming-convention
    const CallbackReqeustObject = Parse.Object.extend('CharterQuoteRequest');

    const newObject = new CallbackReqeustObject();

    newObject.set('flightType', charterQuoteRequest.flightType);
    newObject.set('departure', charterQuoteRequest.flight.departure);
    newObject.set('destination', charterQuoteRequest.flight.destination);
    newObject.set(
      'departureDate',
      moment(charterQuoteRequest.flight.departureDate).toDate()
    );
    newObject.set(
      'returnDate',
      moment(charterQuoteRequest.flight.returnDate).toDate()
    );
    newObject.set(
      'numberOfPassengers',
      charterQuoteRequest.flight.numberOfPassengers
    );

    newObject.set('fullName', charterQuoteRequest.contact.fullName);
    newObject.set('email', charterQuoteRequest.contact.email);
    newObject.set('phone', charterQuoteRequest.contact.phone);

    return newObject.save(null, {
      // eslint-disable-next-line prefer-arrow/prefer-arrow-functions
      success(result) {
        return result;
      },
      // eslint-disable-next-line prefer-arrow/prefer-arrow-functions
      error(result, error) {
        console.log(error);
        return error;
      },
    });
  }

  public addVipMembershipInquiry(
    data: any
  ): Promise<any> {
    return this.runParseCloudFunction('addVipMembershipInquiry', data);
  }

  public postStripePayment(paymentObjectName, paymentDetails): Promise<any> {
    return this.runParseCloudFunction(paymentObjectName, paymentDetails);
  }
  public getStateNames(offset: number = 0, limit: number = 1000): Promise<any> {
    return this.getObjects('stateNames', 0, 1000, null, 'name');
  }

  public async getMemberShipProduct() {
    return await this.runParseCloudFunction('getMembershipStripeProduct');
  }

  public async searchQuotingAircraftOptions(params: {[key: string]: any}) {
    return await this.runParseCloudFunction('searchQuotingAircraftOptions', params);
  }

  public async findBookingPayment(userId: string, paymentIntentClientSecret: string): Promise<BookingPayment | null> {
    // eslint-disable-next-line @typescript-eslint/naming-convention
    const BookingPaymentClass = Parse.Object.extend('BookingPayment');
    const query = new Parse.Query(BookingPaymentClass);
    query.equalTo('paymentIntentClientSecret', paymentIntentClientSecret);
    const userQuery = new Parse.Query(Parse.User);
    userQuery.equalTo('objectId', userId);
    query.matchesQuery('accountHolder', userQuery);
    const result = await query.first();
    if (result) {
      return BookingPayment.createFromParseObject(result);
    } else {
      return null;
    }
  }

  public async requestDeleteAccount() {
    return await this.runParseCloudFunction('requestDeleteAccount');
  }

  public async cancelRequestDeleteAccount() {
    return await this.runParseCloudFunction('cancelRequestDeleteAccount');
  }

  public async generateReferralCodeIfNeeded(): Promise<string> {
    return await this.runParseCloudFunction('generateReferralCodeIfNeeded');
  }

  public async deleteAllUnFinishedWalletTransactions() {
    return await this.runParseCloudFunction('deleteAllUnFinishedWalletTransactions');
  }

  public async getUserByReferralCode(referralCode: string) {
    if (referralCode) {
      const query = new Parse.Query(Parse.User);
      query.equalTo('referralCode', referralCode);
      const results = await query.find();
      if (results.length) {
        return results[0];
      }
    }
    return null;
  }

  public async getWalletTransactions(
    userId: string,
    offset: number = 0,
    limit: number = 1000
  ) {
    const query = new Parse.Query('Transaction');
    // eslint-disable-next-line @typescript-eslint/naming-convention, @typescript-eslint/no-shadow
    const User = Parse.User;
    const user = new User();
    user.id = userId;
    query.equalTo('user', user);
    query.notEqualTo('isDeleted', true);
    query.include([
      'bookingPayment',
      'bookingPayment.flight',
      'bookingPayment.quotingFlight',
      'bookingPayment.quotingFlight.fromAirport',
      'bookingPayment.quotingFlight.toAirport',
      'bookingPayment.quotingFlight.legs',
      'bookingPayment.quotingFlight.mainLegs',
      'bookingPayment.quotingFlight.trivialLegs',
      'booking',
      'booking.flight',
      'booking.flight.aircraft',
      'booking.flight.fromAirport',
      'booking.flight.toAirport',
      'booking.flight.aircraft.operator',
      'booking.quotingFlight',
      'booking.quotingFlight.aircraft',
      'booking.quotingFlight.fromAirport',
      'booking.quotingFlight.toAirport',
      'booking.quotingFlight.operator',
      'booking.quotingFlight.legs',
      'booking.quotingFlight.mainLegs',
      'booking.quotingFlight.trivialLegs',
      'booking.bookingResell',
    ]);
    query.skip(offset);
    query.limit(limit);
    query.descending('createdAt');
    const parseObjects = await this.queryFind(query);
    const transactions = parseObjects.map((obj) => Transaction.createFromParseObject(obj));
    return transactions;
}

  public async didOpenReferralLinkOfReferrer(referralCode: string) {
    return this.runParseCloudFunction('didOpenReferralLinkOfReferrer', { referralCode });
  }

  public async getBookingByBookingPaymentId(bookingPaymentId: string) {
    const query = new Parse.Query('Booking');
    // eslint-disable-next-line @typescript-eslint/naming-convention, @typescript-eslint/no-shadow
    const BookingPayment = Parse.Object.extend('BookingPayment');
    const bookingPayment = new BookingPayment();
    bookingPayment.id = bookingPaymentId;
    query.equalTo('payment', bookingPayment);
    query.include([
      'flight',
      'flight.aircraft',
      'flight.fromAirport',
      'flight.toAirport',
      'flight.aircraft.operator',
      'quotingFlight',
      'quotingFlight.aircraft',
      'quotingFlight.fromAirport',
      'quotingFlight.toAirport',
      'quotingFlight.operator',
      'quotingFlight.legs',
      'payment',
      'payment.discount',
      'member',
      'member.membership',
      'guest',
    ]);
    let obj = null;
    const objs = await this.queryFind(query);
    if (objs.length > 0) {
      obj = objs[0];
    }
    const booking = Booking.createFromParseObject(obj);
    return booking;
  }

  public async createDiscountBookingFlightTransaction(userId: string, amount: number, currency: string) {
    // eslint-disable-next-line @typescript-eslint/naming-convention
    const TransactionParse = Parse.Object.extend('Transaction');
    let obj = new TransactionParse();
    obj.set('amount', amount);
    obj.set('currency', currency);
    obj.set('type', TransactionType.DiscountFlightBooking);
    obj.set('user', {
      // eslint-disable-next-line @typescript-eslint/naming-convention
      __type: 'Pointer',
      className: '_User',
      objectId: userId,
    });
    obj = await obj.save(null);
    obj = await obj.fetch();
    const newTransaction = Transaction.createFromParseObject(obj);
    return newTransaction;
  }

  public async updateBookingOfPayment(bookingPayment: BookingPayment, bookingId: string) {
    if (!bookingPayment.parseObject) {
      return;
    }
    const obj = bookingPayment.parseObject;
    let newObj = obj.save({booking: {
      // eslint-disable-next-line @typescript-eslint/naming-convention
      __type: 'Pointer',
      className: 'Booking',
      objectId: bookingId,
    }});
    newObj = await newObj.fetch();
    const newBookingPayment = BookingPayment.createFromParseObject(newObj);
    return newBookingPayment;
  }

  public async deleteDiscountBookingFlightTransaction(id: string) {
    const query = new Parse.Query('Transaction');
    try {
      const transaction = await query.get(id);
      await transaction.destroy();
      return transaction;
    } catch (error) {
      console.log(`Error delete discount booking flight transaction: ${error}`);
      return null;
    }
  }

  public async getBookingResellsOfBookingId(bookingId: string, offset: number = 0, limit: number = 20) {
    const res = await this.getObjects('BookingResell', offset, limit, [
      'booking',
      'booking.flight',
      'booking.flight.toAirport',
      'booking.flight.fromAirport',
      'booking.flight.aircraft',
      'booking.flight.aircraft.operator',
      'booking.flight.fromCity',
      'booking.flight.toCity',
      'booking.quotingFlight',
      'booking.quotingFlight.aircraft',
      'booking.quotingFlight.fromAirport',
      'booking.quotingFlight.toAirport',
      'booking.quotingFlight.operator',
      'booking.quotingFlight.legs',
    ], null, ((query) => {
      // eslint-disable-next-line @typescript-eslint/naming-convention, @typescript-eslint/no-shadow
      const Booking = Parse.Object.extend('Booking');
      const booking = new Booking();
      booking.id = bookingId;
      query.equalTo('booking', booking);
      return query;
    }));
    const results = res.results;
    const objects = results.map((parseObj) => BookingResell.createFromParseObject(parseObj));
    return objects;
  }

  public async saveQuotingFlightFromFlyeasyObject({
    flyeasyObject, fromAirportIcao, toAirportIcao, departureDate, returnDate, flightType,
  }: {
    flyeasyObject: AircraftOption; fromAirportIcao: string; toAirportIcao: string;
    departureDate: Date; returnDate?: Date; flightType: string;
  }) {
    console.log(`saveQuotingFlightFromFlyeasyObject ${JSON.stringify({
      ...flyeasyObject, fromAirportIcao, toAirportIcao, departureDate, returnDate, flightType
    })}`);
    return this.runParseCloudFunction('saveQuotingFlightFromFlyeasyObject', {
      ...flyeasyObject, fromAirportIcao, toAirportIcao, departureDate, returnDate, flightType
    });
  }

  public async deleteQuotingFlightWithId(id: string) {
    return this.runParseCloudFunction('deleteQuotingFlightWithId', { id });
  }

  private parseInitialize() {
    Parse.initialize(this.parseAppId, this.parseJsKey);
    Parse.serverURL = this.parseServerUrl;
  }

  private async runParseCloudFunction(name: string, params?: any): Promise<any> {
    try {
      return await Parse.Cloud.run(name, params);
    } catch (error) {
      const parseError = error as ParseError;
      if (parseError.code === ParseErrorCode.sessionExpiredOrInvalid) {
        await this.auth.signout().toPromise();
        this.navController.navigateBack('/login');
      }
      throw error;
    }
  }

  private async queryFind(query: any) {
    try {
      return await query.find();
    } catch (error) {
      const parseError = error as ParseError;
      if (parseError.code === ParseErrorCode.sessionExpiredOrInvalid) {
        await this.auth.signout().toPromise();
        this.navController.navigateBack('/login');
      }
      throw error;
    }
  }

  private async queryCount(query: any) {
    try {
      return await query.count();
    } catch (error) {
      const parseError = error as ParseError;
      if (parseError.code === ParseErrorCode.sessionExpiredOrInvalid) {
        await this.auth.signout().toPromise();
        this.navController.navigateBack('/login');
      }
      throw error;
    }
  }

  private async queryGet(query: any, objId: any) {
    try {
      return await query.get(objId);
    } catch (error) {
      const parseError = error as ParseError;
      if (parseError.code === ParseErrorCode.sessionExpiredOrInvalid) {
        await this.auth.signout().toPromise();
        this.navController.navigateBack('/login');
      }
      throw error;
    }
  }

  private async fetchObj(obj: any) {
    try {
      return await obj.fetch();
    } catch (error) {
      const parseError = error as ParseError;
      if (parseError.code === ParseErrorCode.sessionExpiredOrInvalid) {
        await this.auth.signout().toPromise();
        this.navController.navigateBack('/login');
      }
      throw error;
    }
  }

  private createParseObjFromBookingResell(bookingResell: BookingResell) {
    // eslint-disable-next-line @typescript-eslint/naming-convention
    const BookingResellParse = Parse.Object.extend('BookingResell');
    const obj = new BookingResellParse();

    if (obj.id !== 'new') {
      obj.id = bookingResell.id;
    }

    // eslint-disable-next-line @typescript-eslint/naming-convention
    const BookingParse = Parse.Object.extend('Booking');
    const bookingParseObj = new BookingParse();
    bookingParseObj.id = bookingResell.booking?.id;
    obj.set('booking', bookingParseObj);

    obj.set('numberOfSeats', bookingResell.numberOfSeats);
    obj.set('price', bookingResell.price);
    obj.set('currency', bookingResell.currency);
    return obj;
  }
}
