import { Injectable } from '@angular/core';
import {
  ActivatedRouteSnapshot,
  RouterStateSnapshot,
  UrlTree,
  CanLoad,
  UrlSegment,
  Router
} from '@angular/router';
import { Observable } from 'rxjs';
import { CanActivate } from '@angular/router';
import { Route } from '@angular/router';
import { AuthProvider } from './auth/auth';

@Injectable({
  providedIn: 'root'
})
export class SignupGuard implements CanActivate {
  path: ActivatedRouteSnapshot[];
  route: ActivatedRouteSnapshot;
  constructor(private authProvider: AuthProvider, private router: Router) {}

  canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<boolean | UrlTree> | Promise<boolean | UrlTree> | boolean | UrlTree {
    if (this.authProvider.authenticated()) {
      // console.log('signup authguard: redirecting to /home as user is authenticated');
      const query = route.queryParamMap;
      let navigateToMembership = false;
      if (query.has('referral')) {
        localStorage.setItem('referralCode', query.get('referral'));
        if (!this.authProvider.isMember()) {
          navigateToMembership = true;
        }
      }
      console.log('navigateToMembership: ' + navigateToMembership);
      if (navigateToMembership) {
        this.router.navigate([`/buy-membership`], {queryParams: {referral: query.get('referral')}});
      } else {
        this.router.navigate(['/home']);
      }
      return false;
    } else {
      console.log('not authenticated at signup guard');
    }
    return true;
  }
}