import { Component, OnInit } from '@angular/core';
import { ModalController } from '@ionic/angular';
import { environment } from 'src/environments/environment';

@Component({
  selector: 'app-membership-terms',
  templateUrl: './membership-terms.page.html',
  styleUrls: ['./membership-terms.page.scss'],
})
export class MembershipTermsPage implements OnInit {

  constructor(public modalController: ModalController) { }

  get appName(): string {
    return environment.appDisplayName;
  }

  get companyName(): string {
    return environment.appCompanyName;
  }

  ngOnInit() {
  }
  dismissModal(success) {
    this.modalController.dismiss({ success });
  }

}
