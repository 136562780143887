import { User } from '../services/auth/auth';
import { BookingPayment } from './booking-payment.class';
import { Booking } from './booking.class';

export enum TransactionType {
    ReferralRewardReferrer = 0,
    ReferralRewardReferee = 1,
    DiscountFlightBooking = 2,
    BookingResellPayment = 3
}

export class Transaction {
    id?: string;
    user?: User;

    amount?: number;
    currency?: string;

    type?: TransactionType;

    // Info for transaction type ReferralRewardReferrer
    referee?: User;

    // Info for transaction type DiscountFlightBooking
    bookingPayment?: BookingPayment;

    // Info for transaction type BookingResellPayment
    booking?: Booking;

    createdAt?: Date;

    parseObject?: any;

    public static createFromParseObject(parseObj: any): Transaction {
        console.log(`createFromParseObject`);
        const obj = new Transaction();
        obj.id = parseObj.id;
        if (parseObj.get('type') !== undefined && parseObj.get('type') !== null) {
            obj.type = parseObj.get('type');
        }
        if (parseObj.get('user')) {
            obj.user = User.createFromParseObject(parseObj.get('user'));
        }
        if (parseObj.get('bookingPayment')) {
            obj.bookingPayment = BookingPayment.createFromParseObject(parseObj.get('bookingPayment'), {
                includeDiscount: false
            });
        }
        if (parseObj.get('booking')) {
            obj.booking = Booking.createFromParseObject(parseObj.get('booking'));
        }
        obj.amount = parseObj.get('amount');
        obj.currency = parseObj.get('currency');
        obj.createdAt = parseObj.get('createdAt');
        obj.parseObject = parseObj;
        return obj;
      }
}
