import { Coordinate } from './coordinate.interface';
import { AirportLocation, Airport as FlyEasyAirport } from './flyeasy.model';

export class Airport {
  id?: string;
  name?: string;
  timezone?: string;
  icaoCode?: string;
  city?: string;
  elevation?: number;
  title?: string;
  country?: string;
  region?: string;
  iata?: string;
  continent?: string;
  coordinate?: Coordinate;
  type?: string;
  lengthFt?: number;
  widthFt?: number;
  surface?: string;
  sstr?: string;
  parseObject?: any;

  static createFromParseObject(x: any): Airport {
    const f = new Airport();
    f.parseObject = x;
    f.id = x.id;
    f.name = x.get('name');
    f.timezone = x.get('timezone');
    f.icaoCode = x.get('icaoCode');
    f.city = x.get('city');
    f.elevation = x.get('elevation');
    f.title = x.get('title');
    const coordinate = x.get('coordinate');
    if (coordinate) {
      f.coordinate = { lattitude: coordinate.lattitude, longitude: coordinate.longitude };
    }
    f.country = x.get('country');
    f.region = x.get('region');
    f.iata = x.get('iata');
    f.continent = x.get('continent');
    f.type = x.get('type');
    f.lengthFt = x.get('lengthFt');
    f.widthFt = x.get('widthFt');
    f.surface = x.get('surface');
    f.sstr = x.get('sstr');
    f.parseObject = x;
    return f;
  }

  static createFromFlyEasyObject(airport: FlyEasyAirport): Airport {
    if (!airport) {
      return undefined;
    }
    const f = new Airport();
    f.id = airport.id;
    f.name = airport.name;
    f.icaoCode = airport.icao;
    f.city = airport.city;
    f.elevation = airport.elevation;
    f.title = airport.title;
    const lat = airport.lat;
    const lng = airport.lng;
    if (lat !== null && lat !== undefined && lng !== null && lng !== undefined) {
      f.coordinate = { lattitude: lat, longitude: lng };
    }
    f.country = airport.country;
    f.region = airport.country;
    f.iata = airport.country;;
    f.continent = airport.country;
    f.type = airport.type;
    f.lengthFt = airport.length_ft;
    f.widthFt = airport.width_ft;
    f.surface = airport.surface;
    f.sstr = airport.sstr;
    return f;
  }
}
