
export const environment = {
  production: true,
  appDisplayName: 'Tappjet',
  appCompanyName: 'Attune Aviation Ltd',
  logoHome: 'logo_white_black_trans.png',
  parseAppId: 'vj7HApQHVd2tOSLXbYmAkcD1rErAaqSron5pPtJW',
  parseJsKey: '7kMKzsU0mTGOk5Ji9utt14WqA6JmojLvC3uSAeF2',
  stripeKey:
    'pk_live_51LScZgIfDWSxp1ZXcg8MMu9xvkdyo6Cw1AMTfN8VauqpnB2t1ufyQ2qiSfO0Xj7sq1iShcgJmFgJ0OBaADQ7QLbx00n2JmUzzw',
  parseServerUrl: 'https://parseapi.back4app.com/',
  firebaseDynamicLinkPrefx: 'tappjet.page.link',
  firebaseApiKey: 'AIzaSyD45DeGRTNV-cCB6ljcxrJXD7zB86J4OrA',
  androidPackageName: 'com.tappjet.tappjet',
  iosBundleId: 'com.tappjet.tappjet',
  domain: 'tappjet.com',
  branchIOKey: 'key_live_keX6VdYcC4fqglSTAx5WchohsEgbJOqK',
  branchLinkDomain: 'tappjetbr.app.link',
  maxEmptySeats: 3,
  flightPriceRetailRate: 0.15,
  visitWebsite: 'https://www.campaigns.tappjet.com/',
  contactPhone: '1-877-212-5720',
  privacyLink: '/privacy-policy',
  defaultFlightFilterAirports: [],
  showFilterSeatDealsCheckbox: true,
  showMembershipSideMenuItem: true,
  isBookingResellEnabled: true,
  isMemberBenefitsPageEnabled: true,
  isWhiteLabel: false,
  isChooseBookingAircraftTypeEnabled: true,
  flightFilterOperators: [],
  quotingFlightFilterOperators: [],
  quotingFlightFilterParams: {
    aircraftUses: ['pax'],
    allowCabotage: false,
    allowTransient: false,
    homeBaseDistanceNM: 100,
  },
  quotingFlightFilterOnlyAvailable: true,
  quotingFlightFilterQuality: ['tuned'],
  quotingFlightFilterIsFloater: [undefined, false],
  isBookingTermDescriptionLong: true,
  isBookingTermClickable: true,
  creditCardFeeRate: 0.04,
};
