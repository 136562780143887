import { Injectable } from '@angular/core';
import {
  ActivatedRouteSnapshot,
  Router
} from '@angular/router';
import { Observable } from 'rxjs';
import { CanActivate } from '@angular/router';
import { AuthProvider } from './auth/auth';
import { Platform } from '@ionic/angular';
import { ErrorCode } from '../app.constant';

@Injectable({
  providedIn: 'root'
})
export class AdminOrOperatorGuard implements CanActivate {
  path: ActivatedRouteSnapshot[];
  route: ActivatedRouteSnapshot;
  constructor(private authProvider: AuthProvider, 
    private router: Router, private platform: Platform) {}

  canActivate(): boolean | Observable<boolean> | Promise<boolean> {
    if (this.authProvider.isAdmin()) {
      return true;
    }

    if (this.authProvider.isOperator()) {
      if (this.platform.is("desktop")) {
        return true;
      } else {
        this.router.navigate([`/error/${ErrorCode.operatorNotUsingDesktop}`]);
        return false;    
      }
    }

    this.router.navigate(['/login']);
    return false;
  }
}
