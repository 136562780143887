import { Injectable, OnDestroy, OnInit } from '@angular/core';
import { Observable, of, BehaviorSubject, Subscription } from 'rxjs';
import { map, mergeMap } from 'rxjs/operators';
import moment from 'moment';

// Parse
import { Parse } from 'parse';
import { environment } from 'src/environments/environment';
import { FlightOperator } from 'src/app/models/flight-operator.class';
import { Membership } from 'src/app/models/membership.class';
import { FCM } from '@capacitor-community/fcm';
import { Platform } from '@ionic/angular';
import { startOfYesterday } from 'date-fns';

export class User {
  public id?: string;
  public name: string;
  public firstName?: string;
  public middleName?: string;
  public lastName?: string;
  public username?: string;
  public email: string;
  public emailVerified?: boolean;
  public isAdmin?: boolean;
  // public isOperator?: boolean;
  public operator?: FlightOperator | null;
  public password?: string;
  public membership?: Membership;
  public vipMembership?: Membership;
  public birthDate?: string;
  public phoneNumber?: string;
  public citizenship?: string;
  public city?: string;
  public referralCode?: string;
  public referralLink?: string;
  public referralLinkClickCount?: number;
  public referralCount?: number;
  public referralRewardEarning?: number;
  public referrer?: User;
  public requestedDeleteAt?: Date | null;
  public walletBalance?: number;
  public parseObject?: any;

  public static createFromParseObject(userObj: any): User {
    const user = new User();
    user.id = userObj.id;
    user.parseObject = userObj;
    if (!userObj.get) {
      return user;
    }
    user.name = userObj.get('username');
    user.email = userObj.get('email');
    user.emailVerified = userObj.get('emailVerified');
    user.phoneNumber = userObj.get('phoneNumber');
    user.firstName = userObj.get('firstName');
    user.middleName = userObj.get('middleName');
    user.lastName = userObj.get('lastName');
    user.username = userObj.get('username');
    user.isAdmin = userObj.get('isAdmin');
    if (userObj.get('membership')) {
      user.membership = Membership.createFromParseObject(userObj.get('membership'));
    }
    if (userObj.get('vipMembership')) {
      user.membership = Membership.createFromParseObject(userObj.get('vipMembership'));
    }
    user.birthDate = userObj.get('birthDate');
    user.citizenship = userObj.get('citizenship');
    user.city = userObj.get('city');
    if (userObj.get('operator')) {
      user.operator = FlightOperator.createFromParseObject(userObj.get('operator'));
    }
    user.requestedDeleteAt = userObj.get('requestedDeleteAt');
    user.referralCode = userObj.get('referralCode');
    user.referralLink = userObj.get('referralLink');
    user.referralLinkClickCount = userObj.get('referralLinkClickCount');
    user.referralCount = userObj.get('referralCount');
    user.referralRewardEarning = userObj.get('referralRewardEarning');
    user.referrer = userObj.get('referrer');
    user.walletBalance = userObj.get('walletBalance');
    return user;
  }

  public isMember(): boolean {
    const currentTime = new Date().getTime();
    const membershipExpiryTime = this.membership?.expiryDate?.getTime() ?? startOfYesterday().getTime();
    const isMember = (membershipExpiryTime > currentTime);
    return isMember;
  }

  public isVIPMember(): boolean {
      const currentTime = new Date().getTime();
      const vipMembershipExpiryTime = this.vipMembership?.expiryDate?.getTime() ?? startOfYesterday().getTime();
      const isVIP = (vipMembershipExpiryTime > currentTime);
      return isVIP;
  }
}

@Injectable()
export class AuthProvider {
  private parseAppId: string = environment.parseAppId;
  private parseJsKey: string = environment.parseJsKey;
  private parseServerUrl: string = environment.parseServerUrl;

  private currentUserSubject: BehaviorSubject<User> = new BehaviorSubject<User>(null);

  private cachedUserInfo: any;

  constructor(private platform: Platform) {
    this.parseInitialize();

    const isAuthenticated = this.currentUser() != null;
    if (isAuthenticated) {
      this.currentUserSubject.next(this.currentUser());
    }
  }

  public signin(username: string, password: string): Observable<boolean> {
    const self = this;
    return new Observable<void>((subscriber) => {
      subscriber.next();
      subscriber.complete();
    }).pipe(mergeMap(async res => {
      let user = await Parse.User.logIn(username, password);
      user = await user?.fetch({include: ['membership', 'operator']});
      console.log(`signin: ${JSON.stringify(user)}`);
      console.log(
        'User signed in successful with name: ' +
        user.get('username') +
        ' and email: ' +
        user.get('email')
      );
      const webDomain = user.get('webDomain');
      if (!webDomain) {
        user.set('webDomain', environment.domain);
        await user.save();
      }
      self.cachedUserInfo = user;
      self.currentUserSubject.next(User.createFromParseObject(user));
      return true;
    }));
  }

  public async signup(
    username: string,
    password: string,
    email: string,
    referrerUserId: string | null,
    phone: string,
    firstName: string,
    lastName: string,
    city: string
  ) {
    console.log("signup");
    const user = new Parse.User();
    user.set('firstName', firstName);
    user.set('lastName', lastName);
    user.set('username', username);
    user.set('password', password);
    user.set('email', email);
    user.set('city', city);
    user.set('webDomain', environment.domain);
    if (referrerUserId) {
      const query = new Parse.Query(Parse.User);
      const referrer = await query.get(referrerUserId);
      user.set('referrer', referrer);  
    }
    await user.signUp();
    await this.signout().toPromise();
  }

  public signout(): Observable<boolean> {
    const self = this;
    return new Observable(observer => {
      Parse.User.logOut().then(
        () => {
          console.log(`lougout success`);
          this.cachedUserInfo = null;
          self.currentUserSubject.next(null);
          observer.next(true);
          observer.complete();
        },
        error => {
          console.log('lougout error:', error);
          this.cachedUserInfo = null;
          self.currentUserSubject.next(null);
          observer.next(true); // todo: maybe only allow error no 209 (invalid session token)
          observer.complete();
        }
      );
    });
  }

  requestPasswordReset(email: string): Promise<any> {
    return Parse.User.requestPasswordReset(email);
  }

  public currentUser$(): Observable<User> {
    return this.currentUserSubject.asObservable();
  }

  public currentUser(): User {
    const u = Parse.User.current();
    if (u) {
      return User.createFromParseObject(u);
    }
    return null;
  }

  public async updateUser(values: {[key: string] : any}) {
    const u = Parse.User.current();
    if (u) {
      for (const key of Object.keys(values || {})) {
        u.set(key, values[key]);
      }
      await u.save();
    }
  }

  public async refreshCurrentUser() {
    if (this.cachedUserInfo) {
      this.cachedUserInfo = await this.cachedUserInfo.fetch({include: ['membership', 'operator']});
      this.currentUserSubject.next(User.createFromParseObject(this.cachedUserInfo));
    } else {
      if (this.currentUser()) {
        const parseObject = await this.findUser(this.currentUser().id, true);
        this.currentUserSubject.next(User.createFromParseObject(parseObject));
      }
    }
  }

  public authenticated(): boolean {
    const user = this.currentUser();
    const isAuthenticated = (user !== null && user !== undefined);
    // if (isAuthenticated) {
    //   this.currentUserSubject.next(this.currentUser());
    // }

    return isAuthenticated;
  }

  public findUser(id, clearCache: boolean = false): Promise<any> {

    if (this.cachedUserInfo && !clearCache) {
      return new Promise((resolver, reject) => resolver(this.cachedUserInfo));
    }
    console.log(`findUser`);
    return Parse.Cloud.run('fetchUser', { id }).then(u => {
      this.cachedUserInfo = u;
      return u;
    });
    // return this.findObject('User', id);
  }

  public async setFirebaseToken(firebaseToken: string | null) {
    if (this.platform.is('mobile')) {
      return await Parse.Cloud.run('setFirebaseToken', { firebaseToken });
    }
  }

  public isAdmin(): boolean {
    return this.authenticated() && this.currentUser().isAdmin === true;
  }

  public isOperator(): boolean {
    return this.authenticated() && (this.currentUser().operator !== undefined && this.currentUser().operator !== null);
  }

  public isUser(): boolean {
    return this.authenticated() && !this.isAdmin() && !this.isOperator();
  }

  public isMember(): boolean {
    return this.authenticated() && (this.currentUser()?.isMember() ?? false);
  }

  public isVIPMember(): boolean {
    return this.authenticated() && (this.currentUser()?.isVIPMember() ?? false);
  }

  private parseInitialize() {
    Parse.initialize(this.parseAppId, this.parseJsKey);
    Parse.serverURL = this.parseServerUrl;
  }
}
