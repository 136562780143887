import { Injectable } from '@angular/core';
import { ParseProvider } from '../parse/parse';
import { AuthProvider } from '../auth/auth';
import { Subject } from 'rxjs';
import { Flight } from 'src/app/models/flight.class';
import { Booking } from 'src/app/models/booking.class';
import { BookingPayment } from 'src/app/models/booking-payment.class';

@Injectable({
  providedIn: 'root',
})
export class BookingService {
  cachedActiveBookings: any;
  // cachedFlightBookings: {} = {};

  private myActiveBookingsSubject: Subject<Booking[]> = new Subject<
    Booking[]
  >();

  constructor(
    private parseProvider: ParseProvider,
    private authProvider: AuthProvider
  ) {
    this.authProvider.currentUser$().subscribe((u) => {
      if (u == null) {
        this.cachedActiveBookings = null;
        this.myActiveBookingsSubject.next([]);
      }
    });
  }

  get myActiveBookings() {
    return this.myActiveBookingsSubject.asObservable();
  }

  getFlightBookings(flightId: string) {
    return this.parseProvider.getFlightActiveBookings(flightId);
  }

  getMyActiveBookings(refresh = false) {
    if (this.cachedActiveBookings && !refresh) {
      this.myActiveBookingsSubject.next(this.cachedActiveBookings);
      return new Promise((resolver, reject) =>
        resolver(this.cachedActiveBookings)
      );
    }
    return this.parseProvider
      .getActiveBookings(this.authProvider.currentUser().id)
      .then((res) => {
        const list = res.results.map((x) => Booking.createFromParseObject(x));
        this.cachedActiveBookings = list;
        this.myActiveBookingsSubject.next(list);
        return list;
      });
  }

  async placeBooking(booking: Booking) {
    const res = await this.parseProvider.postBooking(booking);
    await this.getMyActiveBookings(true);
    return res;
  }

  async updateBookingOfPayment(bookingPayment: BookingPayment, bookingId: string) {
    return await this.parseProvider.updateBookingOfPayment(bookingPayment, bookingId);
  }

  makeFlightBookingPayment(paymentBody: any) {
    return this.parseProvider
      .postStripePayment('buyFlight', paymentBody)
      .then((res) => res);
  }
}
