import { Component, Input, OnInit } from '@angular/core';
import { ModalController } from '@ionic/angular';

@Component({
  selector: 'app-privacy-policy',
  templateUrl: './privacy-policy.component.html',
  styleUrls: ['./privacy-policy.component.scss'],
})
export class PrivacyPolicyComponent implements OnInit {
@Input() isShownAsModal = true;
  constructor(public modalController: ModalController) { }

  ngOnInit() {
    console.log('isShownAsModal', this.isShownAsModal?'true':'false');
  }
  dismissModal(success) {
    this.modalController.dismiss({ success });
  }
}
