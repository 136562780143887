import moment from 'moment';

export class Guest {
  public id: string;
  public firstName?: string;
  public lastName?: string;
  public email: string;
  public phoneNumber: string;
  public hostMembershipId?: string;
  public birthDate?: string;
  public citizenship?: string;

  public static createFromParseObject(guestObj: any): Guest {
    const guest = new Guest();
    guest.id = guestObj.id;
    guest.email = guestObj.get('email');
    guest.phoneNumber = guestObj.get('phoneNumber');
    guest.firstName = guestObj.get('firstName');
    guest.lastName = guestObj.get('lastName');
    guest.citizenship = guestObj.get('citizenship');
    guest.birthDate = moment(guestObj.get('birthDate')).format('YYYY-MM-DD');

    return guest;
  }
}
