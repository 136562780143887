import { User } from '../services/auth/auth';

export class Membership {
    id: string;
    accountHolder?: User;
    isCompanyType?: boolean;
    jointMember1?: User;
    jointMember2?: User;
    jointMember3?: User;
    expiryDate?: Date;
    parseObject?: any;

    public static createFromParseObject(parseObj: any, includeAccountHolder: boolean = false): Membership {
      const membership = new Membership();
      membership.id = parseObj.id;
      if(includeAccountHolder && parseObj.get('accountHolder')) {
        membership.accountHolder = User.createFromParseObject(parseObj.get('accountHolder'));
      }
      membership.isCompanyType = parseObj.get('isCompanyType');
      if (parseObj.get('jointMember1')) {
        membership.jointMember1 = User.createFromParseObject(parseObj.get('jointMember1'));
      }
      if (parseObj.get('jointMember2')) {
        membership.jointMember2 = User.createFromParseObject(parseObj.get('jointMember2'));
      }
      if (parseObj.get('jointMember3')) {
        membership.jointMember3 = User.createFromParseObject(parseObj.get('jointMember3'));
      }
      membership.expiryDate = parseObj.get('expiryDate');
      membership.parseObject = parseObj;
      return membership;
    }  
}
